.home-page {
  background-color: #000;
  // padding: 0 pxToRem(10);
  .main-layout__content {
    padding: 0 pxToRem(10);
  }

  &__tab-bar {
    margin-top: pxToRem(60);
  }
}

.game-detail {
  background-color: #000000;
  margin-top: pxToRem(3);

  &__rule-title {
    background: #181818;
    color: #e1e1e2;
    font-size: pxToRem(15);
    font-weight: 600;
    padding: pxToRem(6) 0;
    font-family: $primary-font;
  }

  &__value {
    font-family: $primary-font;
    font-size: pxToRem(16);
    font-weight: 600;
    display: flex;
    justify-content: space-around;
    padding: pxToRem(6) 0;
    background-color: #0d0d0d;
    margin: pxToRem(3) 0;
    align-items: center;
  }

  &__btn-detail-rule {
    font-family: $primary-font;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c69e00;
    border-radius: pxToRem(42);
    width: pxToRem(180);
    height: pxToRem(40);
    margin: pxToRem(16) auto 0;
    cursor: pointer;

    button {
      background: transparent;
      outline: none;
      border: none;
      color: #fff;
      font-family: $primary-font;
      font-size: pxToRem(14);
      font-weight: 600;
    }
  }

  &__result {
    margin-top: pxToRem(24);
    .point-title {
      font-family: $second-font;
      font-size: pxToRem(17);
      color: #fff;
      font-weight: bold;
      text-align: left;
    }
    .tabs {
      margin-top: pxToRem(17);
      .game-detail-tab {
        .tab__header-item {
          width: 100%;
          background-color: #181818;
          border-radius: 0;
          height: pxToRem(32);
        }

        .tab__header-item--active {
          background: #383839;
        }
      }

      .tab__label {
        font-size: pxToRem(10);
      }

      .tab__label--active {
        color: #e1e1e2;
      }

      .game-detail-note {
        color: #a4a4a5;
        font-size: pxToRem(11);
        font-family: $primary-font;
        text-align: left;
      }
      .tab__content {
        padding: 0;
      }
      .table {
        margin-top: pxToRem(12);
        &__row {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        &__row-body {
          background-color: #0d0d0d;
          padding: pxToRem(4) 0 pxToRem(4) pxToRem(8);
          margin-top: pxToRem(5);
          min-height: pxToRem(36);
          border-radius: pxToRem(2);
        }

        &__td {
          max-width: pxToRem(40);
          text-align: center;
          font-size: pxToRem(12);
          font-weight: bold;
          color: #e1e1e2;
          padding: pxToRem(5);
          margin: auto;
          background-color: #181818;
          line-height: pxToRem(13);
        }

        &__td-50 {
          width: 50%;
          max-width: 50%;
          font-weight: bold;
          color: #e1e1e2;
          text-align: left;
          font-size: pxToRem(10);
        }

        .break-line-text {
          text-align: left;
          padding: 0;
          margin-top: pxToRem(4);
          font-size: pxToRem(8);
        }

        &__td-same {
          width: 100%;
          height: pxToRem(20);
          background-color: #181818;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.filter {
  margin-top: pxToRem(15);
  padding: 0 pxToRem(15);
  max-width: pxToRem(700);
  margin: 0 auto;
}

.slider-player {
  padding: 0 pxToRem(10);
  margin-top: pxToRem(14);

  .slider-item {
    padding: pxToRem(4);
    &__player {
      padding-top: pxToRem(16);
      background-color: #181818;
      border-radius: pxToRem(2);
      height: pxToRem(101);
      &.player_fw {
        border-bottom: pxToRem(1) solid #ff212e;
      }
      &.player_mf {
        border-bottom: pxToRem(1) solid #71f443;
      }
      &.player_df {
        border-bottom: pxToRem(1) solid #38dbff;
      }
      &.player_gk {
        border-bottom: pxToRem(1) solid #ff7f23;
      }
      &__image {
        position: relative;
        padding: 0 pxToRem(5);
        height: pxToRem(49);
        z-index: 1;
        margin: 0 auto;
        overflow: hidden;
        img {
          width: 100%;
        }
        &__uniform {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          color: #e1e1e2;
          font-size: pxToRem(16);
          font-family: 'Oswald', sans-serif;
          font-weight: 600;
          bottom: 0;
          justify-content: center;
          display: flex;
          text-shadow: 0px 1px 2px #282828;
          -webkit-text-shadow: 0px 1px 2px #282828;
          align-items: center;
        }
      }
      &__name {
        font-family: $primary-font;
        font-weight: 600;
        font-size: pxToRem(10);
        color: #e1e1e2;
        display: flex;
        justify-content: center;
        padding-top: pxToRem(12);
      }
    }
    &__box__info {
      display: flex;
      justify-content: space-between;
      margin-top: pxToRem(11);
      height: pxToRem(32);
      color: #e1e1e2;
      align-items: center;
      border-radius: pxToRem(92);
      background-color: #383839;
      border: pxToRem(1) solid #676767;
      padding: 0 pxToRem(10);
      span.name {
        font-size: pxToRem(9);
      }
      span.point {
        font-family: 'Hiragino Kaku Gothic Pro';
        font-size: pxToRem(11.5);
      }
    }
  }

  .slick-arrow {
    display: none !important;
  }
}

.list-players-scroll {
  position: relative;
  width: 100%;
  overflow: auto;
  max-width: pxToRem(700);
  margin: pxToRem(12) auto;

  &__list {
    display: flex;
    -webkit-transition-property: margin-left;
    transition-property: margin-left;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s;
    margin-left: 0;
  }
  .slide-left {
    transition-property: margin-left;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    margin-left: pxToRem(90);
  }
}
.slider-item {
  padding: pxToRem(4);

  &__player {
    padding-top: pxToRem(16);
    background-color: #181818;
    border-radius: pxToRem(2);
    height: pxToRem(101);
    width: pxToRem(90);
    &.football_fw {
      border-bottom: pxToRem(1) solid #ff212e;
    }
    &.football_mf {
      border-bottom: pxToRem(1) solid #71f443;
    }
    &.football_df {
      border-bottom: pxToRem(1) solid #38dbff;
    }
    &.football_gk {
      border-bottom: pxToRem(1) solid #ff7f23;
    }
    &__image {
      position: relative;
      padding: 0 pxToRem(5);
      height: pxToRem(49);
      z-index: 1;
      margin: 0 auto;
      overflow: hidden;
      img {
        width: 100%;
      }
      &__uniform {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        color: #e1e1e2;
        font-size: pxToRem(16);
        font-family: 'Oswald', sans-serif;
        font-weight: 600;
        bottom: 0;
        justify-content: center;
        display: flex;
        text-shadow: 0px 1px 2px #282828;
        -webkit-text-shadow: 0px 1px 2px #282828;
        align-items: center;
      }
    }
    &__name {
      font-family: 'Hiragino Kaku Gothic Pro';
      font-weight: 600;
      font-size: pxToRem(10);
      color: #e1e1e2;
      display: flex;
      justify-content: center;
      padding-top: pxToRem(12);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &__box__info {
    display: flex;
    justify-content: space-between;
    margin-top: pxToRem(11);
    height: pxToRem(32);
    color: #e1e1e2;
    align-items: center;
    border-radius: pxToRem(92);
    background-color: #383839;
    border: pxToRem(1) solid #676767;
    padding: 0 pxToRem(10);
    span.name {
      font-size: pxToRem(9);
    }
    span.point {
      font-family: 'Hiragino Kaku Gothic Pro';
      font-size: pxToRem(11.5);
    }
  }
}

.player-selecting {
  &--fade-in {
    position: absolute;
    top: pxToRem(5);
    animation: fadeIn 0.5s ease-in both;
  }

  &--fade-out {
    position: absolute;
    top: pxToRem(4);
    animation: fadeOut 0.5s ease-in both;
  }

  &--hidden {
    display: none;
  }

  .slider-item {
    &__player {
      &.football_fw {
        border-bottom: pxToRem(1) solid #ff212e;
      }
      &.football_mf {
        border-bottom: pxToRem(1) solid #71f443;
      }
      &.football_df {
        border-bottom: pxToRem(1) solid #38dbff;
      }
      &.football_gk {
        border-bottom: pxToRem(1) solid #ff7f23;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  to {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
    overflow: hidden;
    transition: overflow 4s linear;
  }
}

.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  min-height: pxToRem(520);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.need-change-player {
  position: fixed;
  background-color: #000;
  min-height: pxToRem(200);
  font-size: pxToRem(16);
  width: 100%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  bottom: 0;

  .player-change {
    width: pxToRem(90);
    margin: 0 auto;
  }

  &__header {
    font-size: pxToRem(13);
    font-weight: bold;
    letter-spacing: 1.23px;
    color: #fff;
    font-family: $primary-font;
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    img {
      width: pxToRem(24);
      height: pxToRem(24);
      cursor: pointer;
      position: absolute;
      right: pxToRem(14);
      top: pxToRem(10);
    }
  }
}

.no-players {
  width: 100%;
  &__wrapper {
    margin: 0 auto;
    text-align: center;
  }

  &__msg {
    font-size: pxToRem(13);
    font-weight: bold;
    letter-spacing: 1.23px;
    margin: pxToRem(20) auto;
    color: #fff;
    font-family: $primary-font;
  }

  .no-player-button {
    color: #c69e00;
    font-size: pxToRem(13);
    background-color: transparent;
    border-style: none;
    padding: 0;
    padding-bottom: pxToRem(4);
    cursor: pointer;
    border-bottom: 1px solid;
  }
}

@include breakpoint(iphonex) {
  .need-change-player {
    min-height: pxToRem(290);
  }
}

.prize-item {
  display: flex;
  width: 100%;
  align-items: center;
  font-family: $primary-font;
  font-weight: 600;
  height: pxToRem(36);
  margin-top: pxToRem(2);
  background-color: #0d0d0d;

  &__rank {
    width: 50%;
    text-align: center;

    p {
      font-size: pxToRem(12);
      color: #a4a4a5;
    }
  }

  &__value {
    width: 50%;
    color: #5e5e61;
    font-family: pxToRem(16);
    text-align: center;
  }
}

.social {
  padding: pxToRem(15) pxToRem(30);
  background-color: #000;
  min-height: pxToRem(246);
  &__header {
    .customLineDraftGame {
      .line-text {
        color: #c69e00 !important;
        font-size: pxToRem(22);
        font-weight: bold;
        top: -13px;
        min-width: pxToRem(205);
        text-align: center;
      }
      span {
        max-width: 17% !important;
        border: pxToRem(0.5) solid #c69e00;
      }
      @include breakpoint(sm320) {
        font-size: pxToRem(22) !important;
        span {
          max-width: 10% !important;
        }
      }

      @include breakpoint(sm360) {
        font-size: pxToRem(20) !important;
        span {
          max-width: 13% !important;
        }
      }
    }
    &__des {
      font-family: $primary-font;
      font-size: pxToRem(14);
      font-weight: bold;
      color: #e1e1e2;
      width: 100%;
      text-align: center;
      margin-top: pxToRem(15);
    }
  }

  &__btn-group {
    display: flex;
    justify-content: center;
    margin-top: pxToRem(17);
    .react-share__ShareButton {
      margin: 0 pxToRem(12);
    }
  }

  &__share-des {
    font-family: $primary-font;
    font-weight: 600;
    color: #e1e1e2;
    line-height: pxToRem(18);
    font-size: pxToRem(11);
    text-align: center;
    margin-top: pxToRem(10);
  }

  &__navigate-group {
    display: flex;
    justify-content: center;

    .btn-navigate {
      min-width: pxToRem(156);
      height: pxToRem(40);
      background-color: #c69e00;
      border-radius: pxToRem(42);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 pxToRem(8);
      margin-top: pxToRem(36);

      button {
        border: none;
        outline: none;
        cursor: pointer;
        color: #fff;
        font-size: pxToRem(14);
        font-family: $primary-font;
        font-weight: 600;
        background-color: transparent;
      }
    }

    @include breakpoint(sm320) {
      .btn-navigate {
        min-width: pxToRem(120);
        height: pxToRem(40);
        background-color: #c69e00;
        border-radius: pxToRem(42);
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          border: none;
          outline: none;
          cursor: pointer;
          color: #fff;
          font-size: pxToRem(12);
          font-family: $primary-font;
          font-weight: 600;
          background-color: transparent;
        }
      }
    }
  }
}
.mg-top__custom {
  margin-top: 3.125rem !important;
}
.padding-20px {
  padding: 0px 20px;
}
.room_blank {
  color: #fff;
  text-align: center;
  height:60vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
  line-height: 25px;
  p {
    margin-bottom: 30px;
    font-size: 15px;
  }
  button {
    color: #fff;
    border-radius: 30px;
    border: 1px solid #c69e00;
    background-color: #c69e00;  
    padding: 5px 20px;
    font-size: 14px;
  }
}
.lang-th * {
  font-family: 'Sarabun', sans-serif !important;
}
.lang-th {
  .facebook-login {
    font-weight: 800
  }
}
.language-th {
  .header__sidebar {
    * {
      font-family: 'Sarabun', sans-serif !important;
    }
  }
  button, 
  .group-filter, 
  .modal-title, 
  input, 
  .social__header__des,
  .social__share-des,
  .content-info,
  .tab__label,
  .rodal-dialog,
  .game-detail,
  .point-title,
  .promotion-code__page,
  .edit-payment,
  .my-page__list__prize,
  .my-page__content__tabs li,
  .mypage-edit__wrapper,
  .account-information__form .form__item label,
  .page__proxy,
  .box-item p,
  .center-tab-how-to *,
  .table__row,
  .ranking-page .box__sharing h3,
  .ranking-page__content__box__tab .ranking__top .tab__header-item .tab__label,
  .ranking-page .tab__content .my__rank .resuft__name .header {
    font-family: 'Sarabun', sans-serif !important;
  }
}

.share-social {
  .social__header__des {
    margin-top: 1.3475rem;
  }
  a {
    height: 43px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: pxToRem(23);
    font-weight: bold;
    line-height: 0;
    color: #fff;
    // background: #06C755;
    background: #4167b2;
    border-radius: pxToRem(30);
    text-decoration: none;
    position: relative;
    img {
      position: absolute;
      right: pxToRem(10);
      top: pxToRem(15);
    }
  }
}
.banner-image__share {
  padding: pxToRem(10) 0;
  img {
    width: 100%;
  }
}

.game-top__page {
  .my-team {
    .btn-join {
      position: initial;
      margin-bottom: pxToRem(14);
      button {
        width: 100%;
      }
    }
  }
  .my-team__match__area {
    width: 82%;
    .team-3:nth-child(4) {
      margin-bottom: 0;
    }
  }
}
.choose-game, .draft-game {
  .main-layout__content {
    padding-top: 3.125rem;
  }
  .header__title {
    justify-content: center;
    display: flex;
    width: 100%;
    p {
      max-width: 70%;
      line-height: 23px;
      text-align: center;
    }
  }
}
.min-23-rem {
  min-height: 22.5rem !important;
}
.countdown-content__title h3, .round-info {
  line-height: 20px;
}
.vr_bk_filter {
  .select-bottom {
    width: 45%;
    .select-bottom__menu {
      width: 100%;
    }
  }
  .group-filter {
    .filter-item {
      width: 45%;
    }
  }
}

.flag-email-modal {
  margin-bottom: pxToRem(40);
  line-height: pxToRem(24);
  span {
    color:#c69e00;
    cursor: pointer;
  }
}

.checkbox-flag-mail label {
  position: relative;
  cursor: pointer;
}

.checkbox-flag-mail input {
  display: none;
}

.checkbox-flag-mail label:before {
  content:'';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #212529;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.checkbox-flag-mail input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 7px;
  width: 5px;
  height: 11px;
  border: solid #212529;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.modal-header {
  position: relative;
  .close-header {
    font-size: pxToRem(20);
    position: absolute;
    top: pxToRem(10);
    right: pxToRem(10)
  }
}
.about-sport {
  display: inline-block;
  margin-bottom: 10px;
  color: #fff;
}

.game-top__page {
  .rodal-mask {
    background:rgba(0, 0, 0, 0.7);
  }

  .rodal-dialog {
    .content__popup__image {
      margin-top: 1.125rem;
    }
    .content__popup {
      min-height: 9rem;
      border: none;
      padding: 0 pxToRem(20);
      .content__popup__image {
        span {
          margin-bottom: 0.75rem;
          line-height: pxToRem(20);
        }
      }
    }

    .content__popup-th {
      min-height: 8rem;
    }
  }
}

.dialog-maintain {
  .modal-header {
    padding: pxToRem(12) 1rem;
  }
  
  .modal-body {
    .checkbox-flag-mail {
      margin: 0.625rem pxToRem(-17);
      border-top: 1px solid #dee2e6;
      padding-top: pxToRem(15);
      margin-bottom: 0;
    }
    
    .flag-email-modal {
      font-family:Verdana, Geneva, Tahoma, sans-serif;
      
      p {
        font-family: "Hiragino Kaku Gothic Pro";
        
        &:first-child {
          margin-top: pxToRem(10);
        }
  
        &:last-child {
          margin-bottom: pxToRem(10);
        }
      }
    }
  }

  .modal-footer {
    display: none;
  }
}
