.account-information {
    font-family: 'Hiragino Kaku Gothic Pro';
    .padding__page {
        max-width: pxToRem(700);
        margin: 0 auto;
        padding: 0 pxToRem(16);
    }
    &__page {
        ul {
            margin-top: pxToRem(35);
            li {
                .item {
                    margin-bottom: pxToRem(8);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: pxToRem(50);
                    background: #0D0D0D;
                    font-weight: 600;
                    border-radius: pxToRem(2);
                    padding: 0 pxToRem(13);
                    &__title {
                        color: #A4A4A5;
                        font-size: pxToRem(12);
                    }
                    &__navigation {
                        font-size: pxToRem(14);
                        position: relative;
                        overflow: hidden;
                        padding-right: pxToRem(28);
                        color: #E1E1E2;
                        img {
                            position: absolute;
                            right: pxToRem(11);
                        }
                    }
                }
            }
        }
    }
    &__form {
        margin-top: pxToRem(36);
        .form__item {
            margin-bottom: pxToRem(24);
            .input__box {
                input {
                    color: #E1E1E2;
                    font-size: 0.75rem !important;
                }
            }
            .select_item {
                display: inline-block;
                position: relative;
                overflow: hidden;
                select {
                    display: block;
                    width: pxToRem(114);
                    height: pxToRem(40);
                    border-radius: pxToRem(2);
                    background: #181818;
                    border: 0;
                    outline: none !important;
                    box-shadow: none !important;
                    color: #e1e1e2;
                }
                img {
                    position: absolute;
                    z-index: 999;
                    right: pxToRem(10);
                    top: pxToRem(17);
                }
            }
            label {
                color: #E1E1E2;
                width: 100%;
                font-size: pxToRem(12);
                line-height: pxToRem(18);
                font-weight: 700;
                margin-bottom: pxToRem(8);
            }
        }
    }
    .btn__action {
        position: fixed;
        bottom: pxToRem(50);
        right: pxToRem(16);
        left: pxToRem(16);
        max-width: pxToRem(700);
        margin: 0 auto;
    }
}