.checkbox {
  &__input {
    display: none;
  }

  &__label {
    position: relative;
    display: inline-block;
    font-size: pxToRem(13);
    line-height: pxToRem(19);
    padding-left: pxToRem(20);
    font-family: $primary-font;

    span {
      font-size: pxToRem(15);
      font-weight: normal;
      line-height: 1.47;
      letter-spacing: pxToRem(-0.54);
      text-align: left;
      color: #e1e1e2;
      display: inline-block;
      margin-top: pxToRem(5);
      margin-left: pxToRem(5);
      font-size: pxToRem(14);

      i {
        margin-left: pxToRem(5);
        font-style: normal;
      }
    }

    &::before {
      height: pxToRem(20);
      width: pxToRem(20);
      border: 1px solid #676767;
      left: pxToRem(-1);
      top: 0;
      position: absolute;
      content: '';
      display: inline-block;
      background-color: #000;
      margin-top: pxToRem(5);
    }

    &::after {
      position: absolute;
      display: inline-block;
      height: pxToRem(7);
      width: pxToRem(7);
      border-left: pxToRem(0.5) solid;
      border-bottom: pxToRem(0.5) solid;
      color: #d6b60a;
      transform: rotate(-45deg);
      content: none;
      left: pxToRem(5);
      top: pxToRem(10);
      opacity: 0;
    }
  }

  &__label--disabled {
    &::before {
      border: 1px solid #d7d8df;
    }
  }

  &--checked {
    .checkbox__label::after {
      content: '';
      opacity: 1;
    }
  }
}
