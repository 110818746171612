.group-filter {
  display: flex;
  font-family: $primary-font;
  font-size: pxToRem(10);
  font-weight: 600;
  color: #e1e1e2;
  width: 100%;
  display: flex;
  justify-content: space-between;

  .filter-item {
    background-color: #383839;
    border: 1px solid #676767;
    border-radius: pxToRem(92);
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  .select-position {
    width: pxToRem(86);
    height: pxToRem(32);
  }
  .select-team {
    width: pxToRem(90);
    height: pxToRem(32);
    cursor: pointer;
  }
  .search-box {
    position: relative;

    .search-input {
      width: pxToRem(151);
      height: pxToRem(32);
      border-radius: pxToRem(92);
      background-color: #383839;
      border: 1px solid #676767;
      outline: none;
      color: #e1e1e2;
      padding-right: pxToRem(30);
      padding-left: 1.9875rem;
    }
    img.search {
      position: absolute;
      left: pxToRem(10);
      top: pxToRem(5);
    }
    .clear__input {
      position: absolute;
      width: 1rem;
      height: 1rem;
      display: flex;
      right: pxToRem(8);
      top: pxToRem(8);
      justify-content: center;
      border-radius: 50%;
      overflow: hidden;
      background: #181818;
      align-items: center;
    }
  }
}
