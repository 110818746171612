.main-layout {
  background-color: #000;
  min-height: 100vh;

  &__content {
    padding-top: pxToRem(50);
    // padding-bottom: pxToRem(50);
    min-height: calc(100vh - 55px);
  }

  &__footer {
    // position: fixed;
    bottom: pxToRem(-1);
    width: 100%;
    height: pxToRem(50);
    background-color: #0d0d0d;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $primary-font;
    font-weight: 600;
    color: #e1e1e2;
    font-size: pxToRem(14);
    z-index: 99;
  }
}
.App, #root, body, html {
  background: #000;
}
