.contact-page {
    .language-ja {
        font-family: 'Hiragino Kaku Gothic Pro';
    }

    .padding__page {
        padding: 0 pxToRem(16);
    }

    .box-item {
        p {
            text-align: center;
            padding: pxToRem(24) pxToRem(16);
            font-size: pxToRem(14);
            line-height: pxToRem(24);
            color: white;
            font-weight: 600;
        }
    }

    .form-contact {
        .valid {
            border-color: #dc3545 !important;
        }

        .item {
            font-weight: 600;
            margin-bottom: pxToRem(16);
            color: #fff;

            span {
                display: inline-block;
                font-size: pxToRem(14);
                width: 100%;
                font-weight: 600;
                margin-bottom: pxToRem(8);
            }

            input {
                background: #181818;
                box-shadow: inset pxToRen(1) pxToRem(1) pxToRem(3) rgba(255, 255, 255, 0.02);
                border-radius: pxToRem(2);
                height: pxToRem(40);
                border-radius: pxToRem(2);
                width: 100%;
                border: none;
                outline: none;
                padding: 0 pxToRem(10);
                color: #fff;
                border: 1px solid transparent;
            }

            textarea {
                background: #181818;
                box-shadow: inset pxToRem(1) pxToRem(1) pxToRem(3) rgba(255, 255, 255, 0.02);
                border-radius: pxToRem(2);
                height: pxToRem(140);
                width: 100%;
                border: none;
                outline: none;
                color: #fff;
                padding: pxToRem(10);
                resize: none;
                line-height: pxToRem(22);
                border: 1px solid transparent;

                &.answer_contact {
                    height: pxToRem(80);
                }
            }
        }

        .item-button {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: pxToRem(50);

            &.button-answer {
                margin-bottom: pxToRem(24);
            }

            button {
                padding: pxToRem(10) pxToRem(24);
                font-style: normal;
                font-weight: 600;
                font-size: pxToRem(14);
                line-height: pxToRem(21);
                display: flex;
                align-items: center;
                text-align: center;
                color: #FFFFFF;
                background: #C69E00;
                border-radius: pxToRem(42);
                border: none;
                outline: none;
            }
        }
    }

    .content-contact {
        color: #fff;

        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: pxToRem(8);

            h3 {
                font-size: pxToRem(15);
                font-weight: 600;
            }

            span {
                font-size: pxToRem(13);
                color: #A4A4A5;
                display: flex;
                align-items: center;

                &.load_more {
                    img {
                        transform: rotate(90deg);
                    -webkit-transform: rotate(90deg);
                    }
                }

                img {
                    margin-left: pxToRem(5)
                }
            }
        }

        .content {
            li {
                margin-bottom: pxToRem(8);
                background: #0D0D0D;
                border-radius: pxToRem(4);
                display: flex;
                align-items: center;
                padding: pxToRem(9) pxToRem(13);
                justify-content: space-between;

                div {
                    font-weight: 600;
                    max-width: 90%;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    &>span {
                        display: flex;
                        margin-bottom: pxToRem(10);
                        color: #A4A4A5;
                        font-size: pxToRem(12);
                        width: 100%;

                        line-height: pxToRem(15);

                        .check-list {
                            display: flex;
                            margin-left: pxToRem(10);
                            align-items: center;
                            background-color: #D6B60A;
                            padding: pxToRem(1) pxToRem(2);
                            line-height: pxToRem(15);
                            color: #0D0D0D;

                            span {
                                background-color: #0D0D0D;
                                width: pxToRem(15);
                                height: pxToRem(15);
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 50%;
                                margin-right: pxToRem(4);

                                img {
                                    height: pxToRem(6);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .content-answer-contact {
        color: #fff;

        .content {
            li {
                margin-bottom: pxToRem(16);

                .item {
                    background: #181818;
                    box-shadow: inset pxToRem(1) pxToRem(1) pxToRem(3) rgba(255, 255, 255, 0.02);
                    border-radius: pxToRem(8);
                    padding: pxToRem(10);

                    .title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: pxToRem(12);

                        .author {
                            display: flex;
                            align-items: center;
                            font-weight: 600;

                            span {
                                img {
                                    height: pxToRem(42);
                                    width: pxToRem(42);
                                    margin-right: pxToRem(8);
                                    border-radius: 50%;
                                }
                            }
                        }

                        &>span {
                            font-weight: 100;
                            font-size: pxToRem(13);
                            color: #A4A4A5
                        }
                    }

                    .content {
                        line-height: pxToRem(22);
                    }
                }

                .time {
                    font-weight: 600;
                    font-size: pxToRem(13);
                    line-height: pxToRem(14);
                    color: #A4A4A5;
                    text-align: right;
                    margin-top: pxToRem(6);
                }
            }

            .q-and-a {
                li {
                    width: 90%;
                    float: right;

                    &.answer {
                        float: left;
                    }
                }
            }
        }
    }

    .form-answer {
        border-top: 1px solid #515151;
        padding: pxToRem(16) pxToRem(16) pxToRem(45);
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #000;

        textarea {
            height: pxToRem(40);
            background: #181818;
            box-shadow: inset pxToRem(1) pxToRem(1) pxToRem(3) rgba(255, 255, 255, 0.02);
            border-radius: pxToRem(2);
            outline: none;
            border: none;
            width: 100%;
            margin-right: pxToRem(8);
            outline: none;
            padding: 0 pxToRem(10);
            resize: none;
            padding-top: pxToRem(7);
            line-height: pxToRem(22);
            color: #fff;
            border: 1px solid transparent;

            &.valid {
                border-color: #dc3545;
            }
        }

        button {
            height: pxToRem(40);
            background: #C69E00;
            display: flex;
            border: none;
            align-items: center;
            justify-content: center;
            border-radius: pxToRem(42);
            color: #fff;
            font-weight: 600;
            padding: pxToRem(10) pxToRem(20);
            white-space: nowrap;
        }
    }
}

.mt-30 {
    margin-top: pxToRem(30);
}

.mt-20 {
    margin-top: pxToRem(20);
}

.contact-answer-page {
    .main-layout__content {
        min-height: calc(100vh - 7.4375rem);
        height: calc(100vh - 7.4375rem);
        overflow-y: auto;
        
    }
    .main-layout__footer {
        display: none;
    }   

    .ref-data {
        margin: auto;
        max-width: pxToRem(653);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            min-width: pxToRem(100);
        }
    }

    &.no-form {
        .main-layout__content {
            min-height: calc(100vh - 0.4375rem);
            height: calc(100vh - 0.4375rem);
        }

        .form-answer {
            display: none;
        }
    }
}

.content-scoll {
    max-height: pxToRem(210);
    overflow-y: auto;
}