.notices-page {
  padding: pxToRem(0) pxToRem(16);
  .all-already-read {
    display: flex;
    justify-content: flex-end;
    margin: pxToRem(16) auto;

    &__button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-weight: bold;
      font-size: pxToRem(12);
      padding: pxToRem(6) pxToRem(8);
      border-radius: pxToRem(18);
      background-color: transparent;
      border: none;
      color: #D6B60A;
      font-family: $primary-font;
      img {
        margin-right: pxToRem(5);
      }
    }
  }

  .notices-page__list {
    .notice-item {
      position: relative;

      &__img {
        background-image: url('https://image-sports-crown.s3-ap-southeast-1.amazonaws.com/image/pic/notice_mail/pic_draft.png');
        width: 100%;
        margin: pxToRem(16) auto;
        text-align: left;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: pxToRem(7);
        height: pxToRem(100);
        border: 1px solid rgba(255, 255, 255, 0.3);
      }

      &__des {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        color: #e1e1e2;
        font-family: $primary-font;
        font-size: pxToRem(16);
        padding: 0 pxToRem(10);
        font-weight: 600;
      }

      &__des-title {
        margin-bottom: pxToRem(10);
        text-align: left;
        line-height: pxToRem(18);
        margin-top: pxToRem(16);
        font-family: 'Hiragino Kaku Gothic Pro';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;
        color: #E1E1E2;
      }

      &__des-sub {
        line-height: pxToRem(18);
      }

      &__time-box {
        font-family: $primary-font;
        font-size: pxToRem(14);
        width: 100%;
        position: absolute;
        top: pxToRem(10);
        color: #e1e1e2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 pxToRem(15);
        font-weight: 600;
        .title__name {
          width: pxToRem(56);
          height: pxToRem(16);
          border-radius: pxToRem(2);
          font-size: pxToRem(8);
          background-color:#C69E00;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .notice-item__time {
          color: #A4A4A5;
          font-size: 8px;
          font-family: 'Hiragino Kaku Gothic Pro';
        }
      }
    }
  }
}

.modal-content__message {
  font-size: pxToRem(14);
  color: #333333;
  font-family: $primary-font;
}
.new-resuft {
  width: 100%;
  color: #fff;
  text-align: center;
  padding-top: 50px;
}