$viewPort: 360;

/* Font assets src */
$font-url: '../assets/fonts/Mitr-Regular.ttf';
$second-font-url: '../assets/fonts/Hiragino-Sans.otf';

/* Variable for font-family */
// Oswald fonts
$primary-font: 'Hiragino Kaku Gothic Pro';
$second-font: 'Hiragino Sans';
// $primary-font: 'Oswald-Bold';

/**
 * Font size
*/
$font-size-xs: 12;
$font-size-sm: 14;
$font-size-base: 16;
$font-size-md: 24;
$font-size-lg: 32;

$deep-cove-transparent: #787991;
$color-input: #505050;
$bg-input: #062332;
$bg_page: #000000;
$orange: #fc6915;
