.select-bottom {
  &__menu {
    background-color: #383839;
    border: 1px solid #676767;
    border-radius: pxToRem(92);
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: pxToRem(86);
    height: pxToRem(32);
    cursor: pointer;
  }
}

@include breakpoint(iphonex) {
  .menu-list {
    height: pxToRem(290) !important;
  }
}
.menu-list {
  position: fixed;
  width: 100%;
  height: 275px;
  background: #e1e1e2;
  z-index: 9999;
  left: 0;
  bottom: -100%;
  // padding: 0 pxToRem(15);

  &--active {
    bottom: 0;
    transition: 350ms;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e1e1e2;
    color: #a4a4a5;
    height: pxToRem(30);
    border-radius: pxToRem(3);
    margin-top: pxToRem(10);
    font-family: $primary-font;
    font-size: pxToRem(16);
    cursor: pointer;

    &--active {
      background-color: #a4a4a5;
      color: #0d0d0d;
    }
  }

  &__wrapper-content {
    padding: 0 pxToRem(15);
    margin-top: pxToRem(20);
  }
  &__header {
    width: 100%;
    height: pxToRem(40);
    background-color: #fff;
    padding: 0 pxToRem(15);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .icon-down {
      transform: rotate(-90deg);
      cursor: pointer;
      margin-left: pxToRem(10);
    }

    .icon-up {
      transform: rotate(90deg);
      cursor: pointer;
    }

    .btn-complete {
      font-family: $primary-font;
      font-size: pxToRem(16);
      font-weight: 600;
      color: #427fd7;
      cursor: pointer;
    }
  }
}
