@include breakpoint(xm480) {
  .line {
    width: 40% !important;
  }
}
.line {
  width: 43%;
  border: pxToRem(0.5) solid #383839;
  display: inline-block;
}

.line-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.line-text {
  color: #a4a4a5;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: pxToRem(-10);
}
