.tab {
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;

    // &::-webkit-scrollbar {
    //   display: none;
    //   -webkit-appearance: none;
    //   background-color: transparent;
    //   opacity: 0;
    // }

    // &::-webkit-scrollbar-thumb {
    //   display: none;
    //   opacity: 0;
    // }

    &-item {
      height: pxToRem(24);
      width: pxToRem(120);
      border-radius: pxToRem(30);

      &--active {
        background: #c69e00;
      }
    }
  }

  &__label {
    height: 100%;
    width: 100%;
    cursor: pointer;
    font-size: pxToRem(12);
    font-family: $primary-font;
    display: flex;
    outline: none;
    color: #a4a4a5;
    transition: 0.3s;
    justify-content: center;
    align-items: center;

    &--active {
      color: #fff;
    }
  }

  &__shadow {
    opacity: 0;
    position: absolute;
    @include boxShadow(0, pxToRem(7), pxToRem(8), 0, rgba(252, 105, 21, 0.5));
    width: 100%;
    height: pxToRem(5);
    top: pxToRem(43);
    background: transparent;
    transition: 0.3s;
    left: 0;
  }

  &__content {
    padding-top: pxToRem(28);
    padding-bottom: pxToRem(30);
  }

  &--hidden {
    display: none;
  }
}
