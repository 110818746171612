.reset-password {
  width: 100%;

  &__form {
    width: 100%;
    margin-top: pxToRem(30);

    &__password {
      margin-top: pxToRem(10);
    }

    &__button-wrapper {
      width: 100%;
      margin-top: pxToRem(22);

      button {
        width: 100%;
        border: none;
        outline: none;
        height: pxToRem(50);
        background-color: #c69e00;
        color: #fff;
        font-family: $primary-font;
        font-weight: bold;
        border-radius: pxToRem(42);
      }
    }
  }
}

.account-page__title__text__reset {
  line-height: 21px;
}
.reset__password {
  font-size: pxToRem(16);
}

.modal-content__message {
  font-size: pxToRem(16);
  line-height: normal;
  text-align: left;
}

.form__email__margin__16 {
  margin: pxToRem(16) 0;
}
.btn-box__back {
  background: none;
  border: none;
  outline: none;
  position: absolute;
  top: pxToRem(10);
  left: pxToRem(5);
}
