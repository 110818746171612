
.my-page {
  font-family: 'Hiragino Kaku Gothic Pro';
  .padding__page {
    padding: 0 pxToRem(16);
    max-width: pxToRem(700);
    margin: 0 auto;
  }
  &__title {
    padding-top: pxToRem(57);
    display: flex;
    justify-content: center;
    flex-flow: column;
    width: 100%;
    align-items: center;
    .avatar {
      width: pxToRem(64);
      height: pxToRem(64);
      border-radius: 50%;
      background:#C4C4C4;
      margin-bottom: pxToRem(20);
      object-fit: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .title {
      font-weight: 600;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.87);
      margin-bottom: pxToRem(20);
    }
    .edit-payment {
      border: 1px solid #676767;
      box-sizing: border-box;
      border-radius: 26px;
      height: pxToRem(40);
      width: pxToRem(140);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: pxToRem(12);
      color: #A4A4A5;
    }
  }
  &__prize {
    background: #0D0D0D;
    // border: 1px solid #C69E00;
    box-sizing: border-box;
    border-radius: 4px;
    // margin-top: pxToRem(33);
    padding: pxToRem(39) 14px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    & > p,& > div {
      flex: 0 0 100%;
      display: flex;
      justify-content: center;
    }
    .name__prize {
      font-weight: 600;
      font-size: pxToRem(14);
      line-height: pxToRem(18);
      text-align: center;
      color: #A4A4A5;
      margin-bottom: pxToRem(29);
    }
    .point__prize {
      font-weight: 600;
      font-size: pxToRem(20);
      line-height: pxToRem(30);
      text-align: center;
      font-size: pxToRem(14);
      color: #D6B60A;
      padding-bottom: pxToRem(10);
    }
    .text__bottom {
      button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: pxToRem(42);
        font-weight: 600;
        font-size: 13px;
        line-height: 21px;
        height: pxToRem(40);
        background-color: #C69E00;
        color: #ffffff;
        margin-bottom: pxToRem(14);
        white-space: nowrap;
      }
      .bottom_action {
        position: relative;
        .help__prize {
          background: #383839;
          width: pxToRem(16);
          height: pxToRem(16);
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          right: pxToRem(-22);
          border-radius: 50%;
        }
      }
    }
    .content__prize {
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 15px;
      text-align: center;
      color: #A4A4A5;
    }
  }
  &__content {
    margin-top: pxToRem(18);
    .tab {
      display: none;
      transition: height 0.3s ease-in-out;
      -webkit-transition: height 0.3s ease-in-out;
      &.active {
        display: block;
      }
    }
    &__tabs {
      ul {
        li {
          .head {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
            padding: 0 pxToRem(16);
            height: pxToRem(50);
            color: rgba(255, 255, 255, 0.87);
            font-size: pxToRem(12);
          }
          .content__tab {
            display: none;
            img.img__100 {
              width: 100%;
              padding: 0 pxToRem(16) pxToRem(16) pxToRem(16);
            }
          }
          &.active_tab {
            .content__tab {
              display: block;
            }
            .head {
              span {
                img {
                  transform: rotate(180deg);
                  -webkit-transform: rotate(180deg);
                }
              }
            }
          }
          background: #383839;
          border-radius: pxToRem(4);
          margin-bottom: pxToRem(8);
        }
      }
    }
  }
  &__action {
    margin-top: pxToRem(111);
    padding-bottom: pxToRem(42);
    button {
      height: pxToRem(50);
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      background: #C69E00;
      border-radius: 42px;
      color: #FFFFFF !important;
      font-weight: 600;
      font-size: pxToRem(16);
      line-height: pxToRem(24);
      margin-bottom: pxToRem(20);
    }
  }
  .rodal-mask {
    background: rgba(0, 0, 0, 0.8);
  }
  .rodal-dialog {
    height: pxToRem(589) !important; 
    .content__popup {
      padding: 0 pxToRem(20);
      background: #151515;
      border-color: #4E4E4E;
      height: 100%;
      &__header {
        padding-top: pxToRem(33);
        margin-bottom: pxToRem(34);
        h3 {
          font-weight: bold;
          font-size: pxToRem(14);
          line-height: pxToRem(21);
          display: flex;
          align-items: center;
          text-align: center;
          color: #E1E1E2;
        }
      }
      .box__main {
        h3 {
          font-size: pxToRem(14);
          color: rgba(187, 161, 72, 1);
          line-height: pxToRem(21);
          font-weight: bold;
          font-family: 'Hiragino Sans';
          margin-bottom: pxToRem(10);
        }
        &__text {
          font-style: normal;
          font-weight: normal;
          font-size: pxToRem(14);
          line-height: pxToRem(21);
          color: #E1E1E2;
          span {
            color: #BBA148;
          }
        }
        &__img {
          display: flex;
          justify-content: center;
          margin-top: pxToRem(14);
          margin-bottom: pxToRem(14);
        }
      }
      .box__main__button {
        width: 100%;
        margin-top: pxToRem(50);
        button {
          height: pxToRem(50);
          background: #BBA148;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          color: rgba(255, 255, 255, 0.87);
          border-radius: pxToRem(2);
          font-size: pxToRem(16);
        }
      }
    }
  }
}

.payment__history {
  padding-bottom: pxToRem(14);
  li {
    margin-bottom: pxToRem(1) !important;
    padding: 0 pxToRem(15);
    .item__payment {
      justify-content: space-between;
      display: flex;
      padding: pxToRem(20);
      background-color: #1a1a1a;
    }
    &:first-child {
      .item__payment {
        border-top-left-radius: pxToRem(4);
        border-top-right-radius: pxToRem(4);
      }
    }
    &:last-child, &.first {
      .item__payment {
        border-bottom-left-radius: pxToRem(4);
        border-bottom-right-radius: pxToRem(4);
      }
    }
    span {
      color: rgba(255,255,255,0.6);
      font-size: pxToRem(10);
    }
    p {
      font-size: pxToRem(12);
      font-weight: 600;
      color: #ffffff;
      margin-top: pxToRem(15);
    }
    .amount__payment {
      img {
        vertical-align: sub;
        position: absolute;
        top: pxToRem(-3);
        left: 0;
      }
      span {
        display: inline-block;
        padding-left: pxToRem(25);
      }
      p {
        padding-left: pxToRem(25);
        position: relative;
        font-weight: 600;
      }
    }
    .blue__payment {
      color: #1cc500;
    }
    .yellow__payment {
      color: #c69e00;
    }
  }
}
.action__payment {
  display: flex;
  justify-content: center;
  padding-bottom: pxToRem(15);
  span {
    width: pxToRem(40);
    height: pxToRem(40);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #1a1a1a;
    border-radius: pxToRem(2);
  }
  div {
    height: pxToRem(40);
    padding: 0 pxToRem(15);
    justify-content: center;
    align-items: center;
    display: flex;
    background: #1a1a1a;
    font-size: pxToRem(12);
    margin: 0 pxToRem(5);
    border-radius: pxToRem(2);
    color: #9b9b9c;
  }
  &__pev {
    transform: rotate(90deg);
    -webkit-transform: rotate(90deg);   
  }

  &__next {
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);   
  }
}
.mypage-prize {
  .my-page__content {
    margin-top: pxToRem(38);
    &__text {
      text-align: center;
      h3 {
        font-weight: bold;
        font-size: pxToRem(14);
        line-height: pxToRem(21);
        color: #E1E1E2;
        margin-bottom: pxToRem(6);
      }
      p {
        font-weight: bold;
        font-size: pxToRem(11);
        line-height: 16px;
        text-align: center;
        color: rgba(164, 164, 164, 0.87);
      }
    }
    &__main {
      margin-top: pxToRem(35);
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 50%;
          padding: 0 pxToRem(5.5);
          margin-bottom: pxToRem(13);
          span {
            height: pxToRem(50);
            display: flex;
            border-radius: pxToRem(4);
            justify-content: center;
            align-items: center;
            width: 100%;
            background: #1A1A1A;
            font-size: pxToRem(14);
            line-height: pxToRem(21);
            text-align: center;
            color: rgba(255, 255, 255, 0.87);

          }
          &.active {
            span {
              background: #720C3B;
            }
          }
          &.un__active {
            span {
              background: #0d0d0d;
              color:#707070;
            }
          }
        }
      }
    }
  }
  .my-page__action {
    margin-top: pxToRem(115);
  }
}

.ticketProcess {
  font-family: 'Roboto', sans-serif;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: pxToRem(20) auto pxToRem(24);
  .processArea {
    position: relative;
    width: calc(100% / 4);
    &::before {
      content: '';
      position: absolute;
      display: block;
      background: #aaabad;
      height: pxToRem(2);
      width: 100%;
      top: pxToRem(11);
    }
    &:nth-child(1)::before  {
      content: '';
      position: absolute;
      display: block;
      background: #aaabad;
      height: pxToRem(2);
      width: 51%;
      top: pxToRem(11);
      left: 50%;
    }
    &:nth-child(4)::before {
      content: '';
      position: absolute;
      display: block;
      background: #aaabad;
      height: 2px;
      width: 51%;
      top: 11px;
      right: 50%;
    }
    .circle {
      position: relative;
      width: pxToRem(25);
      height: pxToRem(25);
      margin: 0 auto;
      border-radius: 50%;
      border: pxToRem(2) solid #7b7d81;
      background-color: #20242b;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .text {
      width: pxToRem(60);
      font-size: pxToRem(14);
      color: rgba(255,255,255,0.87);
      margin: pxToRem(8) auto 0 auto;
      line-height: 1.5;
      text-align: center;
    }
  }
  .selectArea {
    .circle {
      background-color: #c69e00;
      border-color: #c69e00;
    }
  }
}
.selectItem {
  margin-bottom: pxToRem(16);
  font-family: 'Roboto', sans-serif !important;
  line-height: 1.5;
  margin-bottom: pxToRem(80);
  .itemTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 pxToRem(8);
    table-layout: fixed;
    margin: 0 auto;
    tr {
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 2px;
      vertical-align: middle;
      height: pxToRem(53);
      .name {
        text-align: left;
        padding-left: pxToRem(16);
        .ticketNum {
          color: #ffffff;
          font-size: pxToRem(12);
          font-weight: normal;
          img {
            vertical-align: middle;
            margin-right: pxToRem(5);
          }
        }
      }
    }
    .price {
      text-align: right;
      font-family: 'Oswald', sans-serif;
      padding: pxToRem(16) 0;
      padding-right: pxToRem(16);
      font-size: pxToRem(14);
      font-weight: bold;
      color: #ffffff;
    }
  }
  .attentionArea {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 8px;
    margin: 16px auto;
    .text__header {
      text-align: left;
      font-size: pxToRem(14);
      font-weight: bold;
      color: #ffffff;
      padding-left: pxToRem(8);
    }
    .text__note {
      text-align: left;
      font-size: pxToRem(10);
      font-weight: normal;
      color: #616161;
      padding-left: pxToRem(8);
    }
  }
  .document__icon {
    img {
      vertical-align: sub;
    }
  }
  .flowButtons {
    button {
      margin-top: pxToRem(40);
      height: pxToRem(53);
      display: flex;
      justify-content: center;
      align-items: center;
      background: #c69e00;
      border-radius: pxToRem(42);
      color: #fff;
      width: 100%;
    }
  }
}
.redirect_to_home {
  height: pxToRem(50);
  width: pxToRem(40);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
}

.tab__earned {
  padding: 0 pxToRem(16);
  font-family: "Roboto", sans-serif;
  padding-bottom: pxToRem(15);
  li {
    margin-bottom: pxToRem(1) !important;
    .item {
      font-size: pxToRem(14);
      display: flex;
      justify-content: space-between;
      height: pxToRem(82);
      align-items: center;
      background-color: #1a1a1a;
      color: #fff;
      padding: 0 pxToRem(16);
      margin-bottom: pxToRem(1);
      .roomName {
        line-height: 1.2;
        div {
          display: inline-block;
          background-color: rgba(255, 255, 255, 0.1);
          padding: 4px 8px;
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          margin-bottom: pxToRem(8);
          img {
            width: pxToRem(45);
            vertical-align: middle;
            margin-right: pxToRem(4);
          }
        }
      }
      .ticketLabel {
        width: pxToRem(100);
        text-align: right;
        img {
          vertical-align: middle;
          margin-right: pxToRem(4);
          width: pxToRem(20);
        }
      }
    }
  }
}
.active___nav__page {
  background: rgba(255, 255, 255, 0.15) !important;
}
.margin--button__th {
  margin-top: 2rem !important;
}
.mypage-prize {
  .rodal-dialog {
    height: 21rem !important;
  }
}
.my-page__list__prize {
  display: inline-block;
  width: 100%;
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: pxToRem(16);
    line-height: pxToRem(24);
    color: rgba(255, 255, 255, 0.87);
    margin-bottom: pxToRem(17);
  }
  .has_resulf {
    max-height: pxToRem(211);
    overflow-y: auto;
    margin-bottom: 0px;
    li {
      height: pxToRem(60);
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: pxToRem(2);
      font-weight: 600;
      font-size: pxToRem(12);
      line-height: pxToRem(18);
      color: #E1E1E2;
      background: #181818;
      padding: 0 pxToRem(15);
      &:last-child {
        padding-bottom: 0px;
        margin-bottom: 0px;
      }
      span {
        font-style: normal;
        font-weight: 600;
        font-size: pxToRem(18);
        line-height: pxToRem(27);
        display: flex;
        align-items: center;
        text-align: right;
        color: #D6B60A;
      }
      &.no-resulf {
        justify-content: center;
        color: #A4A4A5;
      }
    }
  }
  .view-more {
    float: right;
    color: #D6B60A;
    text-align: center;
    display: inline-block;
    color: #D6B60A;
    margin-bottom: 10px;
    margin-right: 15px;
    margin-top: 10px;
    font-size: pxToRem(13);
    img {
      margin-left: 8px;
      margin-top: -1px;
    }
    .out-lest {
      img {
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
      }
    }
  }
}
.my-page__content__tabs {
  .head {
    cursor: pointer;
    span {
      img {
        transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
      }
    }
  }
}
.my-page__action_fixed {
  position: fixed;
  left: 0;
  right: 0;
  padding: 0 1rem;
  bottom: 2.625rem;
  max-width: 43.75rem;
  margin: 0 auto;
}
.acount-edit {
  ul {
    li {
      background-color: #0D0D0D;
      border-radius: pxToRem(4);
      height: pxToRem(50);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: pxToRem(13);
      margin-bottom: pxToRem(8);
      span {
          color: #A4A4A5;
          line-height: pxToRem(18);
        &:first-child {
          font-size: pxToRem(12);
        }
        &:last-child {
          font-weight: 700;
          font-size: pxToRem(14);
          line-height: pxToRem(21);
        }
      }
    }
  }
  .edit-account {
    text-align: right;
    margin-top: pxToRem(10);
    span {
      color: #d6b60a;
      font-size: pxToRem(13);
      display: inline-block;
      img {
        margin-left: pxToRem(5);
      }
    }
  }
}