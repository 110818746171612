.header {
  position: relative;
  display: flex;
  height: pxToRem(50);
  background-color: #0d0d0d;
  align-items: center;

  &__sidebar {
    position: absolute;
    left: pxToRem(10);
    top: pxToRem(5);
  }

  &__back-btn {
    position: absolute;
    top: pxToRem(12);
    cursor: pointer;
    height: 30px;
    width: 40px;
    padding-left: 10px;
  }

  &__logo {
    margin: 0 auto;
  }

  &__logo-left {
    right: pxToRem(15);
    position: absolute;
    display: flex;
    align-items: center;
    img {
      width: pxToRem(37);
    }
    span {
      color: #e1e1e2;
      font-family: $primary-font;
      font-size: pxToRem(13);
      margin-left: pxToRem(5);
    }
  }

  &__title {
    color: #e1e1e2;
    font-size: pxToRem(16);
    font-family: $primary-font;
    margin: 0 auto;

    &__use-info {
      display: flex;
      align-items: center;
    }

    &__avatar {
      margin-left: pxToRem(6);
      width: pxToRem(24);
      height: pxToRem(24);
      border-radius: 50%;
      display: flex;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      justify-content: center;
      align-items: center;
      img {
        width: pxToRem(24);
        height: pxToRem(24);
      }
    }
    &__username {
      max-width: pxToRem(230);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: $primary-font;
      color: #e1e1e2;
      font-size: pxToRem(12);
      font-weight: 600;
      margin-left: pxToRem(8);
    }
  }

  .text-nav {
    font-family: $primary-font;
    font-size: pxToRem(13);
    color: #e1e1e2;
    font-weight: normal;
    cursor: pointer;
    padding-left: pxToRem(16);
  }
}

.header-wrapper {
  position: fixed;
  width: 100%;
  z-index: 9999;
  top: 0;
}
.box-laguage {
  position: absolute;
  right: 0.625rem;
  top: 0.4325rem;
  .dropdown {
    margin-right: 10px;
    .dropdown-toggle {
      background: none;
      border: 0px;
      outline: none !important;
      padding: 0px;
      box-shadow: none !important;
      img {
        width: 30px;
      }
      &::after {
        position: absolute;
        opacity: 0;
      }
    }
  }
  .dropdown-menu {
    box-shadow: rgba(94, 94, 94, 0.35) 0px 5px 15px;
    position: relative;
    background: #181818;
    height: 70px;
    border: 1px solid #c69e00;
    &::after {
      content: '';
      border: 7px solid transparent;
      right: 7px;
      top: -13px;
      position: absolute;
      border-bottom: 7px solid #181818;
    }
    &::before {
      content: '';
      border: 8px solid transparent;
      right: 6px;
      top: -16px;
      position: absolute;
      border-bottom: 8px solid #c69e00;
    }
    padding: 0px;
    a {
      padding: 0.1rem 0.5rem;
      display: flex;
      color: #e1e1e2;
      position: relative;
      font-size: 13px;
      height: 33.19px;
      width: 100%;
      font-weight: 100;
      background: none !important;
      font-family: sans-serif;
      align-items: center;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 1px;
        left: 0.5rem;
        right: 0.5rem;
        background: rgb(241, 241, 241);
      }
      &:last-child{
        &::after{
          display: none;
        }
      }
      img {
        margin-right: 10px;
        width: 30px;
      }
    }
  }
}