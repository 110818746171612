.countdown-content {
  margin-bottom: pxToRem(30);
  color: #e1e1e2;
  display: flex;
  font-family: 'Hiragino Kaku Gothic Pro';
  justify-content: space-between;
  // padding: 0 pxToRem(10);
  max-width: pxToRem(700);
  margin: 0 auto;
  margin-bottom: pxToRem(22);

  &__title {
    display: flex;
    align-items: center;
    width: calc(100% - pxToRem(180));
    h3 {
      font-size: pxToRem(14);
      font-weight: 600;
    }
  }
  &__area {
    width: pxToRem(180);
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__date {
      width: pxToRem(32);
      height: pxToRem(32);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: pxToRem(16);
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: pxToRem(3);
      position: relative;
      &.first {
        width: pxToRem(46);
        .date_label {
          font-size: pxToRem(10);
          font-family: 'Helvetica';
          margin-top: pxToRem(10);
        }
        .date {
          min-width: pxToRem(26);
        }
      }
      &::after {
        content: '';
        left: pxToRem(3);
        right: pxToRem(3);
        height: pxToRem(1);
        background-color: #d6b60a;
        position: absolute;
        bottom: pxToRem(3);
      }
      &.time::before {
        content: ':';
        left: pxToRem(-5);
        position: absolute;
        top: pxToRem(7);
        color: #717171;
      }
    }
  }
  &.expired {
    .countdown-content__area {
      color: #ff8383;
      &__date {
        &::after {
          background-color: #ff8383;
        }
      }
    }
  }
}
