.mypage-prize-detail {
  padding: 0 pxToRem(16) pxToRem(16);
  max-width: pxToRem(700);
  margin: 0 auto;

  &__title-check-payment {
    font-family: $primary-font;
    font-size: pxToRem(14);
    font-weight: 600;
    color: #e1e1e2;
    text-align: center;
    margin-top: pxToRem(28);
  }
  &__title-fee-payment {
    font-family: $primary-font;
    font-size: pxToRem(11);
    font-weight: 600;
    color: #a4a4a5;
    text-align: center;
    margin-top: pxToRem(8);
  }
  &__form {
    .amount {
      margin-top: pxToRem(36);
      flex-direction: row;
    }
    .transfer {
      margin-top: pxToRem(53);
    }
    &__section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      &__label {
        font-family: $primary-font;
        font-weight: 600;
        font-size: pxToRem(14);
        line-height: pxToRem(21);
        color: rgba(255, 255, 255, 0.87);
      }

      &__nav {
        font-family: $primary-font;
        font-size: pxToRem(12);
        line-height: pxToRem(18);
        font-weight: bold;
        color: #a4a4a5;
        display: flex;
        align-items: center;
        cursor: pointer;

        img {
          margin-left: pxToRem(4);
        }
      }

      &__result {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        height: pxToRem(50);
        background-color: #0d0d0d;
        padding: 0 pxToRem(11);
        border-radius: pxToRem(4);
        margin-top: pxToRem(8);

        &__label {
          font-family: $primary-font;
          font-weight: 600;
          color: #a4a4a5;
          font-size: pxToRem(12);
        }

        &__value {
          font-family: $primary-font;
          font-weight: 600;
          color: #e1e1e2;
          font-size: pxToRem(18);
        }
        &__value-text {
          font-size: pxToRem(14);
          font-family: $primary-font;
          font-weight: 600;
          color: #e1e1e2;
        }
        .transer-amount {
          color: #d6b60a;
        }
      }
    }
  }

  &__btn-group {
    margin-top: pxToRem(52);
    &__note {
      font-family: $primary-font;
      font-size: pxToRem(11);
      font-weight: 600;
      text-align: center;
      color: #a4a4a5;
    }

    .button-apply {
      width: 100%;
      background-color: #c69e00;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: pxToRem(42);
      height: pxToRem(50);
      margin-top: pxToRem(16);
      cursor: pointer;

      &--disable {
        background-color: #383839;
        pointer-events: none;
      }

      button {
        background-color: transparent;
        font-size: pxToRem(16);
        font-weight: 600;
        color: #fff;
        font-family: $primary-font;
        border: none;
        outline: none;
      }
    }
  }

  &__register-payment {
    .payment-info {
      text-align: left;
      margin-top: pxToRem(50);
    }

    &__box {
      background-color: #0d0d0d;
      padding: pxToRem(32) 0 pxToRem(48);
      margin-top: pxToRem(8);
      width: 100%;

      &__content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .no-payment {
          font-family: $primary-font;
          font-size: pxToRem(12);
          font-weight: 600;
          color: #a4a4a5;
        }

        button {
          cursor: pointer;
          background-color: #c69e00;
          border-radius: pxToRem(42);
          outline: none;
          border: none;
          color: #fff;
          font-size: pxToRem(14);
          font-weight: 600;
          font-family: $primary-font;
          width: pxToRem(160);
          height: pxToRem(30);
          margin-top: pxToRem(26);
        }
      }
    }
  }
}

.mypage-prize-confirm-success {
  padding: 0 pxToRem(16) pxToRem(49);
  &__des {
    font-family: $primary-font;
    font-size: pxToRem(16);
    font-weight: 600;
    color: rgba(255, 255, 255, 0.87);
    text-align: center;
    margin-top: pxToRem(55);
  }
  &__box {
    font-family: $primary-font;
    padding: pxToRem(15) 0;
    border: 1px solid #d6b60a;
    box-sizing: border-box;
    border-radius: pxToRem(4);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: pxToRem(28);

    &__amount {
      color: #d6b60a;
      font-size: pxToRem(20);
      font-weight: 600;
    }

    &__date-transfer {
      margin-top: pxToRem(7);
      color: #a4a4a5;
      font-size: pxToRem(12);
      font-family: $primary-font;
      text-align: center;
    }
  }

  &__notice {
    margin-top: pxToRem(36);
    p {
      color: #a4a4a5;
      font-size: pxToRem(12);
      line-height: pxToRem(15);
      font-family: $primary-font;
      font-style: normal;
      font-weight: normal;
      margin-top: pxToRem(20);
    }
  }

  &__social {
    display: flex;
    justify-content: center;
    margin-top: pxToRem(51);
    .react-share__ShareButton {
      margin: 0 pxToRem(12);
    }
  }
  &__sns-share {
    font-family: $primary-font;
    color: #e1e1e2;
    font-weight: pxToRem(600);
    font-size: pxToRem(14);
    line-height: pxToRem(21);
    text-align: center;
    margin-top: pxToRem(17);
  }

  .button-apply {
    width: 100%;
    background-color: #c69e00;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: pxToRem(42);
    height: pxToRem(50);
    margin-top: pxToRem(100);
    cursor: pointer;

    button {
      background-color: transparent;
      font-size: pxToRem(16);
      font-weight: 600;
      color: #fff;
      font-family: $primary-font;
      border: none;
      outline: none;
    }
  }
}
.has_resulf {
  padding: 0px pxToRem(15) pxToRem(15) pxToRem(15);
}
