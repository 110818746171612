.ranking-page {
  font-family: 'Hiragino Kaku Gothic Pro';
  .padding__page {
    padding: 0 pxToRem(16);
    max-width: pxToRem(700);
    margin: 0 auto;
  }
  &__content {
    &__box__top {
      margin-top: pxToRem(15);
      background: #0d0d0d;
      border: pxToRem(1) solid #c69e00;
      box-sizing: border-box;
      border-radius: pxToRem(2);
      display: flex;
      padding-top: pxToRem(8);
      padding-bottom: pxToRem(7);
      flex-wrap: wrap;
      .box__title {
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: pxToRem(12);
        line-height: pxToRem(18);
        color: #e1e1e2;
        span:first-child {
          display: inline-block;
          margin-right: pxToRem(5);
        }
        margin-bottom: pxToRem(7);
      }
      .box__point {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: baseline;
        span {
          font-size: pxToRem(14);
          line-height: pxToRem(21);
          color: #e1e1e2;
        }
        .point {
          font-weight: 600;
          font-size: pxToRem(20);
          line-height: pxToRem(30);
          margin-left: pxToRem(17);
          color: #d6b60a;
          span {
            color: #d6b60a;
          }
        }
      }
    }
    &__box__tab {
      margin-top: pxToRem(16);
      .ranking__top {
        .tab__header-item {
          height: pxToRem(32);
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #181818;
          border-radius: 0;
          line-height: pxToRem(15);
          width: 100%;
          .tab__label {
            font-size: pxToRem(10);
          }
          &--active {
            background-color: #383839;
            color: #e1e1e2;
          }
          &:first-child {
            border-top-left-radius: pxToRem(3);
            border-bottom-left-radius: pxToRem(3);
          }
          &:nth-child(2) {
            margin: 0 pxToRem(2);
          }
          &:last-child {
            border-top-right-radius: pxToRem(3);
            border-bottom-right-radius: pxToRem(3);
          }
        }
      }
    }
  }
  .box__sharing {
    margin-top: pxToRem(14);
    h3 {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      display: flex;
      color: #e1e1e2;
      font-weight: 600;
      font-size: pxToRem(12);
      line-height: pxToRem(18);
      margin-bottom: pxToRem(15.32);
    }
    .box__social {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      span {
        width: pxToRem(32);
        height: pxToRem(32);
        line-height: pxToRem(32);
        justify-content: center;
        display: flex;
        align-items: center;
        border-radius: 50%;
        margin: 0 pxToRem(10);
        &.icon__facebook {
          background: #176ae6;
          button {
            display: flex;
          }
        }
        &.icon__line {
          background: #00b900;
          button {
            display: flex;
          }
        }
      }
    }
  }
  .tab__content {
    padding: 0;
    .my__rank {
      min-height: auto;
      border: 0;
      .my-team__match {
        margin-top: 0;
        padding-top: pxToRem(15);
        .my__match__image {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background-size: cover;
        }
        .player {
          flex-wrap: wrap;
          height: pxToRem(100);
          margin-bottom: pxToRem(7);
          .player__name {
            bottom: pxToRem(-10);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .player__number {
            font-family: 'Oswald', sans-serif;
            top: pxToRem(55);
            text-shadow: 0px 1px 2px #282828;
          }
          img {
            width: 5.225rem;
            height: 4.35rem;
          }
        }
        .box--point {
          width: pxToRem(57);
          height: pxToRem(23);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: pxToRem(12);
          line-height: pxToRem(19.5);
          background: rgba(13, 13, 13, 0.6);
          color: #e1e1e2;
          border-radius: pxToRem(11.5);
          font-family: 'Hiragino Kaku Gothic Pro';
          margin-bottom: pxToRem(10);
          &.max--active {
            color: #ffb017;
          }
          &.max--active__second {
            color: #74ffd5;
          }
          small {
            font-size: pxToRem(11);
          }
        }
      }
      .resuft__name {
        width: pxToRem(108);
        height: pxToRem(66);
        background: #0d0d0d;
        border-radius: pxToRem(3);
        position: absolute;
        bottom: pxToRem(9.34);
        left: pxToRem(9.34);
        .header {
          height: pxToRem(22);
          color: rgba(255, 255, 255, 0.87);
          display: flex;
          font-size: pxToRem(10);
          justify-content: center;
          border-bottom: 0.5px solid #383839;
        }
        .content {
          color: #d6b60a;
          font-size: pxToRem(16);
          font-weight: 600;
          line-height: pxToRem(24);
          justify-content: center;
          align-items: center;
          display: flex;
          height: pxToRem(44);
          span {
            font-size: pxToRem(12);
            line-height: pxToRem(18);
          }
        }
      }
    }
  }
}
.box-team__7 {
  .player {
    margin: 0 pxToRem(35);
  }
}

.player-ranking__page {
  .tabs {
    margin-top: pxToRem(27);
    // .tab__header {
    //     border-bottom: pxToRem(1) solid #181818;
    // }
    .tab__header-item {
      border-bottom: pxToRem(1) solid #181818;
      padding: pxToRem(5) 0;
      height: auto;
      background: none;
      &.tab__header-item--active {
        border-bottom: pxToRem(1) solid #d6b60a;
      }
    }
  }
}

.content__game {
  .list__team {
    margin-top: pxToRem(30);
    padding-bottom: pxToRem(30);
    li {
      .item__team {
        height: pxToRem(66);
        display: flex;
        overflow: hidden;
        background: #0d0d0d;
        border-radius: pxToRem(4);
        margin-bottom: pxToRem(8);
        .item__image {
          padding-top: pxToRem(8);
          margin-left: pxToRem(-28);
          width: pxToRem(102.87);
          height: pxToRem(98);
          position: relative;
          .box___number {
            position: absolute;
            font-size: pxToRem(20);
            line-height: pxToRem(19);
            color: rgba(255, 255, 255, 0.87);
            letter-spacing: pxToRem(1.5375);
            font-family: 'Oswald', sans-serif;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            top: pxToRem(24);
            font-weight: 800;
            text-shadow: 0 1px 2px #282828;
            -webkit-text-shadow: 0 1px 2px #282828;
          }
          img {
            width: 100%;
          }
        }
        .content__team {
          width: 100%;
          padding: pxToRem(15) pxToRem(14) pxToRem(13) 0;
          span {
            font-weight: 600;
            font-size: pxToRem(12);
            line-height: pxToRem(12);
            color: #e1e1e2;
          }
          .box__player {
            margin-top: pxToRem(7);
            padding-left: pxToRem(8);
            position: relative;
            display: flex;
            justify-content: space-between;
            width: 100%;
            color: #e1e1e2;
            :after {
              content: '';
              position: absolute;
              left: 0;
              top: pxToRem(3);
              bottom: pxToRem(2);
              width: pxToRem(2);
              background-color: #fff;
            }
            &.text_football_fw {
              :after {
                background-color: #ff212e;
              }
            }
            &.text_football_mf {
              :after {
                background-color: #71f443;
              }
            }
            &.text_football_df {
              :after {
                background-color: #38dbff;
              }
            }
            &.text_football_gk {
              :after {
                background-color: #ff7f23;
              }
            }
            .name__player {
              font-weight: 600;
              font-size: .75rem;
              line-height: pxToRem(19);
            }
            .point__player {
              font-size: .85rem;
            }
          }
        }
      }
    }
    .none-item {
      color: #e1e1e2;
      margin-top: pxToRem(15);
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }
}
.result-football {
  margin-top: pxToRem(16);
  padding: 0 pxToRem(15);
  .result-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #181818;
    padding: pxToRem(21) pxToRem(15);
    margin-top: pxToRem(8);

    &__name {
      font-family: $primary-font;
      font-weight: pxToRem(600);
      font-size: pxToRem(12);
      color: #e1e1e2;
    }

    &__value {
      color: #d6b60a;
      font-family: $primary-font;
      font-weight: 600;
    }
  }
}

.list-ranking {
  .ranking-item {
    display: flex;
    align-items: center;
    height: pxToRem(60);
    background-color: #0d0d0d;
    margin-top: pxToRem(8);
    border-radius: pxToRem(4);
    cursor: pointer;
    &>div:last-child {
      width: 100%;
    }
    &--active {
      border: 1px solid #c69e00;
      background: #181818;
    }
    &__stt {
      width: pxToRem(30);
      font-size: pxToRem(14);
      color: #e1e1e2;
      font-family: $primary-font;
      text-align: center;
    }

    &__avatar {
      margin-left: pxToRem(6);
      // width: pxToRem(37);
      // height: pxToRem(32);
      padding:  2px;
      border-radius: 50%;
      display: flex;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      justify-content: center;
      align-items: center;
      &.fee_charge {
        border: 2px solid #d6b60a;
      }
      img {
        width: pxToRem(32);
        height: pxToRem(32);
        border-radius: 50%;
      }
    }

    &__info {
      margin-left: pxToRem(10);
      font-family: $primary-font;
      font-size: pxToRem(12);
      font-weight: 600;
      &__name {
        color: #e1e1e2;
      }

      &__amount-wrapper {
        display: flex;
        justify-content: space-between;
        padding-right: pxToRem(20);
      }

      &__amount-value {
        color: #d6b60a;
        margin-top: pxToRem(5);
        span {
          font-style: normal;
          font-weight: 600;
          font-size: pxToRem(12);
          line-height: pxToRem(18);
          display: flex;
          align-items: center;
          color: #505050;
        }
      }

      &__point {
        margin-top: pxToRem(5);
        margin-left: pxToRem(10);
        font-family: $primary-font;
        font-size: pxToRem(12);
        color: #e1e1e2;
        font-weight: 600;
      }
    }
  }
}
.score-page {
  font-family: 'Hiragino Kaku Gothic Pro';
  .padding__page {
    padding: 0 pxToRem(16);
    max-width: pxToRem(700);
    margin: 0 auto;
  }
  &__content {
    font-weight: 600;
    font-size: pxToRem(14);
    line-height: pxToRem(21);
    color: #e1e1e2;
    margin-top: pxToRem(22);
    .box--shadow__member {
      margin-top: pxToRem(14);
      height: pxToRem(66);
      border-radius: pxToRem(4);
      background-color: #181818;
      li {
        .item__team {
          height: pxToRem(66);
          display: flex;
          overflow: hidden;
          background: #0d0d0d;
          border-radius: pxToRem(4);
          margin-bottom: pxToRem(8);
          .item__image {
            padding-top: pxToRem(8);
            margin-left: pxToRem(-28);
            width: pxToRem(102.87);
            height: pxToRem(98);
            position: relative;
            .box___number {
              position: absolute;
              font-size: pxToRem(20);
              line-height: pxToRem(19);
              color: rgba(255, 255, 255, 0.87);
              letter-spacing: pxToRem(1.5375);
              font-family: 'Oswald', sans-serif;
              left: 0;
              right: 0;
              text-shadow: 0 1px 2px #282828;
              -webkit-text-shadow: 0 1px 2px #282828;
              display: flex;
              justify-content: center;
              top: pxToRem(24);
              font-weight: 800;
            }
            img {
              width: 100%;
            }
          }
          .content__team {
            width: 100%;
            padding: pxToRem(15) pxToRem(14) pxToRem(13) 0;
            span {
              font-weight: 600;
              font-size: pxToRem(12);
              line-height: pxToRem(12);
              color: #e1e1e2;
            }
            .box__player {
              margin-top: pxToRem(7);
              padding-left: pxToRem(8);
              position: relative;
              display: flex;
              justify-content: space-between;
              width: 100%;
              color: #e1e1e2;
              :after {
                content: '';
                position: absolute;
                left: 0;
                top: pxToRem(3);
                bottom: pxToRem(2);
                width: pxToRem(2);
                background-color: #fff;
              }
              &.text_football_fw {
                :after {
                  background-color: #ff212e;
                }
              }
              &.text_football_mf {
                :after {
                  background-color: #71f443;
                }
              }
              &.text_football_df {
                :after {
                  background-color: #38dbff;
                }
              }
              &.text_football_gk {
                :after {
                  background-color: #ff7f23;
                }
              }
              .name__player {
                font-weight: 600;
                font-size: .75rem;
                line-height: pxToRem(19);
              }
              .point__player {
                font-size: .85rem;
              }
            }
          }
        }
      }
    }
  }
  .box__list_point {
    margin-top: pxToRem(16);
    ul {
      li {
        margin-bottom: pxToRem(8);
        .item {
          background: #0d0d0d;
          border-radius: pxToRem(2);
          height: pxToRem(36);
          padding: 0 pxToRem(12);
          display: flex;
          justify-content: space-between;
          align-items: center;
          .first {
            color: #a4a4a5;
            display: flex;
            width: 100%;
            justify-content: space-between;
            h3 {
              font-weight: 600;
              font-size: pxToRem(12);
              line-height: pxToRem(18);
            }
            p {
              font-weight: 600;
              font-size: pxToRem(13);
              line-height: pxToRem(20);
              span {
                display: inline-block;
                margin-left: pxToRem(12);
              }
            }
          }
          .second {
            margin-left: pxToRem(44);
            font-weight: 600;
            font-size: pxToRem(14);
            line-height: pxToRem(20);
            display: flex;
            text-align: right;
            justify-content: flex-end;
            width: pxToRem(50);
            color: rgba(255, 255, 255, 0.87);
          }
        }
      }
    }
  }
}

.no-content {
  text-align: center;
  font-size: pxToRem(16);
  font-family: $primary-font;
  color: #e1e1e2;
  font-weight: 600;
  margin-top: pxToRem(30);
}
.content__game .list__team li .item__team__active {
  background-color: rgba(198, 158, 0, .3);
}

.ranking__top--box.tab__header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  li.tab__header-item {
    flex: 50%;
    box-shadow: 0 0 0 5px black;
    margin: 0 0 5px 0 !important
  }
}

.team-page {
  ul:first-child li:nth-child(1) div,
  ul:first-child li:nth-child(2) div {
    color: #ffffff;
    background: #0D0D0D;
    width: 100%;
    margin-top: pxToRem(10);
  }
}

.search-payment {
  h3 {
    color: #ffffff;
    text-align: center;
    margin: pxToRem(19) 0 pxToRem(17);
  }
  .input_group {
    position: relative;
    display: flex;
    background: #181818;
    box-shadow: inset pxToRem(1) pxToRem(1) pxToRem(3) rgba(255, 255, 255, 0.02);
    border-radius: pxToRem(2);
    input {
      width: 100%;
      border: 0;
      height: pxToRem(40);
      font-style: normal;
      font-weight: 300;
      font-size: pxToRem(16);
      outline: none;
      background: none;
      padding: 0 pxToRem(10);
      color: #ffffff;
    }
    & > img {
      margin: 0 pxToRem(5) 0 pxToRem(15);
    }
    span {
      width: pxToRem(19);
      height: pxToRem(16);
      background: #383839;
      border-radius: 50%;
      margin-right: pxToRem(10);
      margin-top: pxToRem(12);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.header-team {
  width: 100%;
  display: inline-block;
  text-align: center;
  font-size: pxToRem(15);
  color: #fff;
  padding: pxToRem(27) 0 pxToRem(16);
}