.loadingLayer{
  position: fixed;
  height: 100vh;
  width:100%;
  top:0;
  left: 0;
  background-color:transparent;
  z-index: 50;

  .loadingContent{
    position: absolute;
    width:100%;
    top:45%;
    left:50%;
    transform: translate(-50%,-50%);

    .loadingMsg{
      margin: 0 auto;
      font-size: 16px;
      font-weight: bold;
      font-family: 'Futura';
      text-align: center;
      color: #fff;
    }

    .loadingImg{
      display: block;
      width: 66px;
      margin:24px auto 0 auto;
    }
  }
}