.my-team {
  display: flex;
  min-height: pxToRem(450);
  background-color: #181818;
  max-height: pxToRem(700);
  flex-direction: column;
  max-width: pxToRem(700);
  margin: pxToRem(16) auto;
  outline: none;
  border: 2px solid #181818;
  border-radius: pxToRem(6);

  &--disable {
    pointer-events: none;
  }

  &__label {
    color: #e1e1e2;
    font-family: $primary-font;
    font-size: pxToRem(10);
    text-align: center;
    span {
      margin: 0 pxToRem(16);
      padding-bottom: pxToRem(3);
    }
  }

  @include breakpoint(sm768) {
    &__match {
      width: pxToRem(420);
      margin: pxToRem(26) auto;
    }
  }

  &__match {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: pxToRem(26);

    &__area {
      // position: relative;
      // padding: 0 pxToRem(10);

      &__position {
        display: flex;
        justify-content: center;
        margin-bottom: pxToRem(15);
      }
    }
  }

  &__info {
    padding: pxToRem(10) pxToRem(12);
    font-family: $primary-font;
    font-weight: 600;
    font-size: pxToRem(13);
    background-color: #0d0d0d;

    .round-info {
      span:nth-child(1) {
        color: #d6b60a;
      }
      span:nth-child(2) {
        color: #e1e1e2;
        margin-left: pxToRem(8);
      }
    }

    .round-money-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: pxToRem(10);

      img {
        outline: none;
        cursor: pointer;
      }
    }

    .round-money {
      font-size: pxToRem(12);
      font-family: $second-font;
      font-family: bold;
      color: #a4a4a5;
      display: flex;
      align-items: center;

      span:nth-child(2) {
        font-weight: bold;
        color: #d6b60a;
        font-size: pxToRem(18);
        margin-left: pxToRem(8);
      }
    }
  }

  .btn-join {
    font-family: $primary-font;
    position: absolute;
    border-radius: pxToRem(42);
    background-color: #c69e00;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: pxToRem(30);
    cursor: pointer;
    right: pxToRem(10);
    font-size: pxToRem(14);
    &.disable {
      background: #5a5a5a;
    }
    button {
      width: pxToRem(105);
      height: pxToRem(40);
      font-size: pxToRem(14);
      color: #fff;
      font-weight: 600;
      background: transparent;
      border: none;
      outline: none;
    }
  }
}

@include breakpoint(sm320) {
  .player {
    width: pxToRem(59) !important;
  }
}

@include breakpoint(sm300) {
  .player {
    width: pxToRem(69) !important;
  }
}

.player {
  width: pxToRem(74);
  height: pxToRem(69);
  margin: 0 pxToRem(8);
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;

  &--active {
    z-index: 11;
    filter: drop-shadow(1px 1px 8px #fff);
  }

  img {
    width: pxToRem(74);
    height: pxToRem(60);
  }

  &__name {
    width: pxToRem(80);
    height: pxToRem(24);
    background: #0d0d0d;
    color: #e1e1e2;
    font-size: $primary-font;
    font-size: pxToRem(10);
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: pxToRem(5);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__number {
    color: #e1e1e2;
    font-size: pxToRem(16);
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    position: absolute;
    top: pxToRem(15);
    width: 100%;
    text-align: center;
    text-shadow: 0px 1px 2px #282828;
    -webkit-text-shadow: 0px 1px 2px #282828;
  }
}

.player[disabled] {
  pointer-events: none;
  cursor: none;
}

.football_fw {
  border-bottom: pxToRem(1) solid #ff212e;
}

.football_mf {
  border-bottom: pxToRem(1) solid #71f443;
}
.football_df {
  border-bottom: pxToRem(1) solid #38dbff;
}
.football_gk {
  border-bottom: pxToRem(1) solid #ff7f23;
}
.player__image {
  .box--point {
    width: 2.5625rem;
    height: 1.1375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: .65rem;
    line-height: 1.21875rem;
    background: rgba(13, 13, 13, 0.6);
    color: #e1e1e2;
    border-radius: 0.71875rem;
    font-family: "Hiragino Kaku Gothic Pro";
    margin-bottom: 0.625rem;
    top: -17px;
    left: 16px;
    small {
      font-size: .5875rem;
    }
  }
}
.resuft__name__top {
  width: pxToRem(108);
  height: pxToRem(66);
  background: #0d0d0d;
  border-radius: pxToRem(3);
  position: absolute;
  bottom: pxToRem(9.34);
  left: pxToRem(9.34);
  .header {
    height: pxToRem(22);
    color: rgba(255, 255, 255, 0.87);
    display: flex;
    font-size: pxToRem(10);
    justify-content: center;
    border-bottom: 0.5px solid #383839;
  }
  .content {
    color: #d6b60a;
    font-size: pxToRem(16);
    font-weight: 600;
    line-height: pxToRem(24);
    justify-content: center;
    align-items: center;
    display: flex;
    height: pxToRem(44);
    span {
      font-size: pxToRem(12);
      line-height: pxToRem(18);
    }
  }
}
.my-team {
  position: relative;
}
.total-scrow {
  font-size: pxToRem(12);
  line-height: pxToRem(18);
  display: flex;
  align-items: center;
  color: #A4A4A5;
  margin-left: pxToRem(33);
  span {
    font-style: normal;
    font-weight: 600;
    font-size: pxToRem(18);
    line-height: pxToRem(18);
    display: flex;
    margin-left: pxToRem(8);
    align-items: center;
    color: #D6B60A;
  }
}
.show-error-team {
  position: absolute;
  bottom: pxToRem(5);
  justify-content: center;
  display: flex;
  color: #c60e1e;
  left: 0;
  font-size: pxToRem(13);
  right: 0;
}
