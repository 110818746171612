.promotion-code {
    font-family: 'Hiragino Kaku Gothic Pro';
    .padding__page {
        padding: 0 pxToRem(16);
        max-width: pxToRem(700);
        margin: 0 auto;
    }
    .main-layout {
        background-color: #000;
        height: 100vh;
        min-height: pxToRem(500);
    }
    &__page {
        h1 {
            margin-top: pxToRem(64);
            font-weight: 600;
            color: #E1E1E2;
            font-size: pxToRem(14);
            line-height: pxToRem(21);
            text-align: center;
            margin-bottom: pxToRem(22);
        }
        input {
            font-weight: 100;
            font-size: pxToRem(16) !important;
            color: #E1E1E2;
        }
    }
    .promotion-code__action {
        position: fixed; 
        left: 0;
        right: 0;
        padding: 0 pxToRem(16);
        bottom: pxToRem(42);
        max-width: pxToRem(700);
        margin: 0 auto;
    }
    .promotion-code__disabled {
        button {
            color: #A4A4A5;
            background: #383839;
        }
    }
}
.promotion-code__status {
    .header__back-btn {
        display: none;
    }
    .promotion-code__page {
        margin-top: pxToRem(189);
        text-align: center;
    }
    .image_status {
        display: flex;
        justify-content: center;
        span {
            display: flex;
            width: pxToRem(54);
            height: pxToRem(54);
            justify-content: center;
            align-items: center;
            margin-bottom: pxToRem(28);
            background: #1AB892;
            border-radius: 50%;
        }
    }
    h2 {
        font-weight: 600;
        font-size: pxToRem(14);
        line-height: pxToRem(21);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #E1E1E2;
    }
}

.promotion-list__check {
    margin-top: pxToRem(40);
    text-align: center;

    button {
        padding: pxToRem(12) pxToRem(22);
        min-width: pxToRem(200);
        border-radius: pxToRem(30);
        border: pxToRem(1) solid #c69e00;
        background: transparent;
        color: #c59f00;
        font-size: pxToRem(14);
    }
}

.promotion-list {
    color: #fff;
    text-align: center;

    ul {
        li {
            margin-bottom: pxToRem(16);
            background: #181818;
            border-radius: pxToRem(4);
            padding: pxToRem(16);
            border: 1px solid transparent;

            &.active {
                border-color: #D6B60A;
            }
            div {
                &.mark_new {
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        width: pxToRem(7);
                        height: pxToRem(7);
                        border-radius: 50%;
                        background: #FF212E;
                        top: pxToRem(-10);
                        right: pxToRem(-10);
                    }
                }
                & > div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    &.promotion-ltop {
                        margin-bottom: pxToRem(15);
                    }
                    &.promotion-lbot {
                        span {
                            font-size: pxToRem(12);
                            color: #A4A4A5;
                            .exprestion {
                                color: #FF212E;
                            }
                            .use {
                                color: #D6B60A;
                            }
                        }
                    }
                }
            }
        }
    }
}