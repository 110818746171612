.nav-menu {
  background-color: #212122;
  max-width: 70%;
  position: fixed;
  width: 100%;
  top: 0;
  left: -100%;
  transition: 850ms;
  min-height: 100vh;
  max-width: pxToRem(375);
  z-index: 99;

  .navbar-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: pxToRem(28) pxToRem(18) pxToRem(11);

    &__logo {
      display: flex;
      align-items: center;
      img {
        width: pxToRem(20);
        height: pxToRem(12);
      }
      p {
        margin-left: pxToRem(10);
        font-size: pxToRem(16);
        font-family: $primary-font;
        color: #e1e1e2;
      }
    }
  }

  .nav-menu-items {
    padding-top: pxToRem(10);

    &.have-new-code {

      .menu-item:nth-child(2) {
        
        p {
          position: relative;
          padding-right: pxToRem(15);

          &::after {
            content: "";
            top: pxToRem(4);
            right: 0;
            position: absolute;
            width: pxToRem(8);
            height: pxToRem(8);
            border-radius: 50%;
            background: #FF212E;
          }
        }
      }
    }

    .menu-item {
      display: flex;
      align-items: center;
      width: 100%;

      img {
        margin-left: 0;
      }

      span {
        color: #e1e1e2;
        font-size: pxToRem(16);
        font-family: $primary-font;
        font-weight: 600;
      }
      &__active {
        background-color: #333334;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.05);
      }
      a {
        padding: pxToRem(15) pxToRem(18);
        width: 100%;
        text-decoration: none;
        display: flex;
        align-items: center;

        p {
          font-family: $primary-font;
          font-size: pxToRem(16);
          font-weight: 600;
          color: #e1e1e2;
          margin-left: pxToRem(13);
        }
      }
    }
  }
  .navbar-line {
    .line {
      width: 50% !important;
    }
  }
}

.logout {
  padding: pxToRem(15) pxToRem(18);
  display: flex;
  align-items: center;
  width: 100%;
  color: #e1e1e2;
  font-size: pxToRem(16);
  font-family: $primary-font;
  font-weight: 600;
  margin-top: pxToRem(30);
  margin-bottom: pxToRem(110) !important;
  p {
    margin-left: pxToRem(13);
  }
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
  bottom: 0;
  overflow-y: auto;
}

.have--role__admin {
  display: flex;
  flex-wrap: wrap;
  .tool--bar {
    color: #fff;
    margin-left: 20px;
    &>div {
      border: 1px solid #fff;
      padding: 8px  15px;
      display: inline-block;
    }
  }
}

.choose-language {
  margin-top: pxToRem(20);
  padding: 0.9375rem 1.125rem;
  font-size: pxToRem(12);
  
  p {
    font-style: normal;
    font-weight: 600;
    color: #fff;
    line-height: pxToRem(18);
    margin-bottom: pxToRem(8);
  }

  .dropdown {
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: none;
      outline: none;
      width: 100%;
      background: #383839 !important;
      box-shadow: none !important;
      height: 50px;
      border-radius: 4px;

      span {
        display: flex;
        font-size: pxToRem(13);
        align-items: center;
      }
    }
  }

  .dropdown-menu {
    width: 100%;
    background: #181818;
    border-top-left-radius: 0px;
    border-top-left-radius: 0px;

    a {
      color: #fff;
      display: flex;
      align-items: center;
      position: relative;
      font-size: pxToRem(13);

      &:first-child::after {
          content: "";
          position: absolute;
          bottom: 0;
          height: 0.5px;
          left: 0.5rem;
          right: 0.5rem;
          background: #383839;
      }
    }
  }

  img {
    height: pxToRem(30);
    margin-right: pxToRem(10);
   
  }
}

.language-ja {
  .choose-language {
    font-family: "Hiragino Kaku Gothic Pro";
  }
}

body {
  &.has-menu {
    overflow: hidden;
  }
}