.modal-header {
  justify-content: center;

  .modal-title {
    font-size: pxToRem(15);
    font-family: $primary-font;
    font-weight: 600;
    color: #000;
  }
}
.modal {
  z-index: 99999999
}
.modal-body {
  font-family: $primary-font;
  text-align: center;
  p:nth-child(2) {
    margin-top: pxToRem(10);
  }
}

.modal-footer {
  justify-content: center;

  .btn-modal-confirm {
    font-family: $primary-font;
    border-radius: 4px;
    font-size: 14px;
    background-color: #c69e00;
    border: none;
    outline: none;
    min-width: pxToRem(150);
    height: pxToRem(35);
    color: #fff;
  }
  .btn-modal-cancel {
    color: #333333;
    background-color: #fefefe;
    border: 1px solid #616161;
  }
}
.modal-black {
  .modal-content {
    background-color: #000000;
    color: #e1e1e2;
  }
  .modal-header {
    background-color: #0d0d0d;
    border-bottom: 0px;
    .modal-title {
      color: #e1e1e2;
    }
    .close {
      position: absolute;
      right: 15px;
      background-color: transparent;
      border: 0px;
      cursor: pointer;
    }

    .back {
      position: absolute;
      left: pxToRem(15);
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
  .modal-footer {
    border: 0;
    flex-wrap: inherit;
    button {
      border-radius: pxToRem(42);
      width: 100%;
      padding: pxToRem(25) 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.modal-fullscreen {
  .modal-header {
    .modal-title {
      font-size: pxToRem(15);
    }
  }
}
.modal {
  z-index: 99999;
}
.close-disabled {
  pointer-events: none;
  opacity: 0.7;
}