.how-to-play {
  font-family: 'Hiragino Kaku Gothic Pro';
  .padding__page {
    padding: 0 pxToRem(16);
    max-width: pxToRem(700);
    margin: 0 auto;
  }
  .how-to-slider {
    position: relative;
    .slider-item {
      position: relative;
      overflow: hidden;
      min-height: pxToRem(370);
      .box-item {
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        h1 {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 900;
          font-size: pxToRem(26);
          line-height: pxToRem(30);
          text-align: center;
          letter-spacing: 0.2em;
          color: #D6B60A;
          text-shadow: 0 0 pxToRem(14) rgba(0, 0, 0, 0.25);
          backdrop-filter: blur(pxToRem(44));
          margin-bottom: pxToRem(4);
        }
        p {
          font-family: 'Hiragino Kaku Gothic Pro';
          font-style: normal;
          font-weight: 700;
          font-size: pxToRem(12);
          line-height: pxToRem(16);
          text-align: center;
          color: #E1E1E2;
          margin-bottom: pxToRem(10);
        }
        img {
          width: 9.6875rem;
          display: inline-block;
        }
      }
      img {
          width: 100%;
      }
    }
    .slick-dots {
      position: relative;
      bottom: 0;
      margin-top: -2.9375rem;
      margin-bottom: pxToRem(39);
      li {
        background: rgba(196, 196, 196, 0.2);
        border-radius: 50%;
        &.slick-active {
          background: #C4C4C4;
        }
      }
    }
  }
  .my-page__content__tabs {
    ul {
      li {
        height: pxToRem(60);
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Hiragino Sans';
        font-style: normal;
        font-weight: bold;
        font-size: pxToRem(14);
        line-height: pxToRem(21);
        text-align: center;
        letter-spacing: pxToRem(1.23);
        color: rgba(255, 255, 255, 0.87);
        border-radius: pxToRem(2);
        position: relative;
        span {
          position: absolute;
          right: pxToRem(14);
          img {
            height: pxToRem(9);
            transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
          }
        }
      }
    }
  }
}
/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.how-to-play {
  .slick-arrow {
    display: none !important;
  }
}
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 4px;
  height: 4px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: 'slick';
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}
.slick-prev:before {
  content: '←';
}
[dir='rtl'] .slick-prev:before {
  content: '→';
}

.slick-next {
  right: -25px;
}
[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}
.slick-next:before {
  content: '→';
}
[dir='rtl'] .slick-next:before {
  content: '←';
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 4px;
  height: 4px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 4px;
  height: 4px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 6px;
  line-height: 4px;
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 4px;
  content: '•';
  text-align: center;
  opacity: 0.25;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: black;
}

.how-to-point {
  .ticket_button {
    position: fixed;
    right: pxToRem(16);
    top: pxToRem(18);
    font-size: pxToRem(14);
    font-family: "DIN Alternate";
    color: #e1e1e2;
    cursor: pointer;
    z-index: 9999;
    img {
      width: pxToRem(37);
      vertical-align: middle;
    }
  }
  font-family: 'Hiragino Kaku Gothic Pro';
  .padding__page {
    padding: 0 pxToRem(16);
    max-width: pxToRem(700);
    margin: 0 auto;
  }
  .tab__content {
    padding-top: pxToRem(8);
  }
  .tab__header {
    .tab__header-item {
      height: pxToRem(34);
      width: 33.333%;
      border-radius: 0;
      background-color: rgba(255,255,255,0.1);
      &:first-child {
        border-radius: pxToRem(4) 0 0 pxToRem(4);
      }
      &:last-child {
        border-radius: 0 pxToRem(4) pxToRem(4) 0;
      }
      &--active {
        background: #616161;
      }
    }
  }
  .table-added {
    font-family: 'Roboto', sans-serif;
    border-spacing: 0 4px;
    border-collapse: separate;
    tr {
      td {
        padding: pxToRem(8);
        font-size: pxToRem(15);
        height: pxToRem(40);
        vertical-align: middle;
        font-weight: bold;
        color: #e1e1e2;
        text-align: center;
        background-color: rgba(255,255,255,0.05);
        &.label-td {
          position: relative;
          text-align: left;
          font-size: 12px;
          font-weight: normal;
          color: rgba(255,255,255,0.6);
          padding-left: 12px;
          span {
            margin-top: pxToRem(10);
            display: inline-block;
          }
          &.is_j_only::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: pxToRem(4);
            height: 100%;
            border-radius: pxToRem(2) 0 0 pxToRem(2);
            background-color: #c69e0a;
          }
        }
      }
    }
  }
  .memo {
    margin: pxToRem(4) auto pxToRem(16);
    text-align: left;
    color: rgba(255,255,255,0.6);
    font-family: 'Roboto', sans-serif;
    line-height: 1.5;
    font-size: pxToRem(14);
    div {
      margin-bottom: pxToRem(3);
    }
  }
  .ozz_title {
    font-size: pxToRem(14);
    font-weight: bold;
    color: #616161;
    margin: pxToRem(10) auto pxToRem(10);
    display: flex;
    justify-content: center;
    &.ozz_title__16 {
      margin-top: pxToRem(16);
    }
  }
  .normal_ozz-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: pxToRem(8) auto;
    .normal_ozz-table {
      width: 48%;
      border-collapse: separate;
      border-spacing: 0 pxToRem(4);
      color: rgba(255,255,255,0.6);
      tr {
        background-color: rgba(255, 255, 255, 0.05);
        height: pxToRem(40);
        border-radius: pxToRem(2);
        font-family: "Helvetica Neue", Helvetica Neue;
        td {
          padding: pxToRem(8);
          font-size: pxToRem(15);
          font-weight: bold;
          color: #e1e1e2;
          letter-spacing: pxToRem(0.44);
          vertical-align: middle;
        }
        .label-td {
          position: relative;
          text-align: left;
          font-size: pxToRem(12);
          color: rgba(255,255,255,0.6);
          letter-spacing: pxToRem(1.23);
        }
      }
    }
  }
  .national_ozz-table {
    border-collapse: separate;
    border-spacing: 0 4px;
    color: rgba(255,255,255,0.6);
    tr {
      background-color: rgba(255, 255, 255, 0.05);
      height: pxToRem(40);
      border-radius: pxToRem(2);
      font-family: "Helvetica Neue", Helvetica Neue;
      td {
        padding: pxToRem(8);
        font-size: pxToRem(15);
        font-weight: bold;
        color: #e1e1e2;
        letter-spacing: pxToRem(0.44);
        vertical-align: middle;
        text-align: center;
      }
      .label-td {
        position: relative;
        text-align: left;
        font-size: pxToRem(12);
        color: rgba(255,255,255,0.6);
        letter-spacing: pxToRem(1.23);
      }
      .mark-td {
        position: relative;
        font-size: pxToRem(12);
        color: rgba(255,255,255,0.6);
        letter-spacing: pxToRem(1.23);
      }
    }
  }
}

.page__proxy {
  font-style: normal;
  font-weight: normal;
  font-size: pxToRem(14);
  line-height: pxToRem(25);
  font-family: 'Hiragino Kaku Gothic Pro';
  padding: pxToRem(24) 0;
  // display: flex;
  align-items: center;
  color: #E1E1E2;
  .th {
    font-family: sans-serif;
  }
  h2 {
    margin-bottom: pxToRem(20);
    font-size: pxToRem(20);
    font-weight: 700;
  }
  span {
    font-size: pxToRem(16);
    display: inline-block;
    margin-bottom: pxToRem(10);
    font-weight: 600;
  }
}
.action-silde {
  position: absolute;
  top: 48%;
  z-index: 99;
  display: flex;
  width: 100%;
  padding: 0 pxToRem(15);
  justify-content: space-between;
  img {
    transition: all 0.25s ease-in-out;
    -webkit-transition: all 0.25s ease-in-out;
  }
}
.text-2 .next img {
  opacity: 0;
}
.text-0 .prev img {
  opacity: 0;
}
.game_how_to {
  .tab__header-item {
    &:first-child { 
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
    }
    &:last-child {
      border-top-right-radius: 5px !important;
      border-bottom-right-radius: 5px !important;
    }
  }
}
.how_to__top {
  li {
    height: 2.5rem;
    background: none;
    width: 50%;
    border-radius: 0;
    &.tab__header-item--active {
      border-bottom: 1px solid #c69e00;
      .tab__label {
        color: #c69e00;
      }
    }
  }
}
.how-to--resuft {
  margin-top: 0px;
  .tabs {
    margin-top: 0px;
  }
}
.center-tab-how-to {
  h1 {
    font-family: 'Hiragino Kaku Gothic Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #E1E1E2;
    margin-bottom: 27px;
  }
  ul {
    li {
      background: none;
      text-align: left !important;
      font-weight: normal !important;
      display: inline-block !important;
      height: auto !important;
      margin-bottom: pxToRem(20);
      width: 100%;
      span {
        color: #c69e00;
        position: relative !important;
        right: inherit !important;
      }
    }
    .how-to-change-image {
      width: 100%;
      text-align: center;
      display: flex !important;
      img {
        width: 100px;
        margin-left: 17px;
        margin-top: 5px;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .how-to-play .how-to-slider .slider-item .box-item h1 {
    margin-bottom: 20px;
  }
  .how-to-play .how-to-slider .slider-item .box-item p {
    margin-bottom: 25px;
  }
  .how-to-play .how-to-slider .slider-item .box-item img {
    width: 18.6875rem;
  }
  .tab__header.how_to__top {
    justify-content: space-between
  }
}
.payment-page {
  .main-layout {
    padding-bottom: 0px;
    &__content {
      min-height: calc(100vh - 77px);
    }
  }
  .padding__page {
    height: auto;
  }
}
.maintain-page {
  .main-layout {
    &__content {
      min-height: calc(100vh - 50px);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      padding: 0px 25px;
      line-height: 23px;
      text-align: center;
    }
  }
}
.invite-box {
  font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  color: #fff;
  
  button {
    height: 3.125rem;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: #C69E00;
    border-radius: 42px;
    color: #FFFFFF !important;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1.25rem;
    border: 0;
    margin-top: pxToRem(50);
  }
}
.invite-page {

  .invite-box {
    &__header {
      text-align: center;
      font-weight: 600;
      font-size: pxToRem(20);
      line-height: pxToRem(30);

      p {
        &:last-child {
          color: #D6B60A;
          margin-top: pxToRem(5);
          margin-bottom: pxToRem(20);
        }
      }
    }

    &__image {
      img {
        width: 100%;
      }
    }

    &__description {
      border: pxToRem(1) solid #D6B60A;
      padding: pxToRem(15);
      margin-top: pxToRem(20);

      ul {
        li {
          margin-bottom: pxToRem(20);
          font-size: pxToRem(16);
          line-height: pxToRem(24);
          position: relative;
          padding-left: pxToRem(15);

          &::after {
            content: ".";
            position: absolute;
            left: pxToRem(5);
            top: pxToRem(7);
          }

          &:last-child {
            margin-bottom: 0;
          }

          span {
            color: #D6B60A;
          }
        }
      }
    }
  }

  .rodal-mask {
    background: rgba(0, 0, 0, 0.7);
  }

  .rodal-dialog {
    .content__popup {
      min-height: 9rem;
      border: none;
      padding: 0 1.25rem;
      .content__popup__image {
        margin-top: 1.125rem;
        span {
          margin-bottom: 0.75rem;
          line-height: 1.25rem;
        }
      }
    }
  }
}