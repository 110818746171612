@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;600&display=swap');

@font-face {
  font-family: 'Hiragino Kaku Gothic Pro';
  src: url('../../../assets/fonts/Hiragino-Kaku-Gothic-Pro.otf') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.detail-player {
  line-height: 1.5;
  height: pxToRem(125);
  background-color: #0D0D0D;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-family: 'Hiragino Kaku Gothic Pro';
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;

  &__topic__left {
    width: 95px;

    &__uniform {
      margin-top: pxToRem(56);
      margin-left: pxToRem(-64);
      width: pxToRem(147);
      height: pxToRem(147);
      font-size: pxToRem(50);
      overflow: hidden;

      &__image {
        position: relative;
        z-index: 2;

        img {
          width: 100%;
        }
      }

      &__number {
        position: absolute;
        top: 33%;
        left: 50%;
        font-family: 'Oswald', sans-serif;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-size: 100%;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.87);
        text-shadow: 0px 1px 2px #282828;
        -webkit-text-shadow: 0px 1px 2px #282828;
      }
    }
  }

  &__topic__right {
    width: calc(100% - pxToRem(95));
    text-align: left;

    span {
      font-size: pxToRem(11);
    }

    &__team__name {
      display: inline-block;
      vertical-align: middle;
      color: #A4A4A5;
      font-size: pxToRem(12);
    }

    &__name {
      text-align: left;
      font-weight: bold;
      font-size: pxToRem(16);
      margin: 0 auto;
    }

    &__info__label {
      padding: pxToRem(2) 0;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      width: pxToRem(70);
      color: #121212;
      border-radius: pxToRem(1);
      background-color: #676767;
      font-size: pxToRem(9);
      font-weight: 600;
      margin-right: pxToRem(5);
    }

    &__info__number {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      font-size: pxToRem(11);
      color: #E1E1E2;
      font-weight: bold;
    }

    &__image {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      font-size: pxToRem(13);
      color: #E1E1E2;
      font-weight: bold;

      img {
        vertical-align: sub;
        margin-right: pxToRem(4);
        width: pxToRem(17);
      }
    }
  }

  &__title {
    h3 {
      font-size: pxToRem(14);
      font-weight: bold;
      text-align: left;
      margin-top: pxToRem(22);
    }
  }

  &__content {
    margin-top: pxToRem(15);

    .tabs {
      .tab__header {
        justify-content: space-between;

        &-item {
          height: auto;
          border-radius: pxToRem(4);
          background-color: #181818;
          border: pxToRem(1) solid #383839;
          padding: pxToRem(10);

          &--active {
            background-color: #0D0D0D;
            border: pxToRem(1) solid #D6B60A;
          }

          .tab__label {
            flex-flow: column;
            font-size: pxToRem(10);
            font-weight: 600;
            margin-top: pxToRem(4);

            .point {
              margin-top: pxToRem(10);
              display: flex;
              vertical-align: middle;
              font-size: pxToRem(13);
              font-weight: bold;
              height: pxToRem(19.5);
              align-items: center;

              span {
                font-size: pxToRem(11);
              }

              img {
                width: pxToRem(20);
                vertical-align: middle;
                margin-right: pxToRem(3);
              }
            }
          }
        }
      }
    }

    .tab__body {
      padding: 0;
    }

    .tab__content {
      padding-top: pxToRem(15);
      padding-bottom: 0px;
    }

    &__game__core {
      line-height: 1.5;
      position: relative;
      overflow: hidden;
      background-color: #0D0D0D;
      ;
      padding: pxToRem(7) 0;
      margin: 0 auto;

      &__section {
        font-size: pxToRem(12);
        color: #A4A4A5;
      }
    }

    &__game__resuft {
      font-size: pxToRem(14);
      position: relative;
      overflow: hidden;
      margin-top: pxToRem(4);
      padding-top: pxToRem(6);
      color: #E1E1E2;

      &::after {
        content: '';
        left: pxToRem(50);
        right: pxToRem(50);
        top: 0;
        height: pxToRem(1);
        background: #383839;
        position: absolute;
      }

      span {
        font-weight: 600;

        &:first-child {
          display: inline-block;
          margin-left: pxToRem(12);
          // color: #D6B60A;
        }

        &:last-child {
          display: inline-block;
          margin-right: pxToRem(12);
        }
      }
    }

    &__player__left {
      position: absolute;
      top: pxToRem(8);
      width: pxToRem(70);
      left: pxToRem(-22);

      &__team {
        position: relative;
        z-index: 2;
      }

      img {
        width: 100%;
      }
    }

    &__player__right {
      position: absolute;
      top: pxToRem(8);
      width: pxToRem(70);
      right: pxToRem(-22);

      &__team {
        position: relative;
        z-index: 2;
      }

      img {
        width: 100%;
      }
    }

    &__list {
      margin-top: pxToRem(15);

      ul {
        li {
          background: #0d0d0d;
          padding: 0 0 0 pxToRem(10);
          font-size: pxToRem(12);
          border-radius: pxToRem(1);
          display: flex;
          align-items: center;
          margin-bottom: pxToRem(8);
          justify-content: space-between;

          .item__name {
            width: 60%;
            text-align: left;
            color: #A4A4A5;
          }

          .item__number {
            width: 20%;
            display: flex;
            justify-content: center;

            span {
              line-height: pxToRem(32);
              font-size: pxToRem(13);
            }

            img {
              vertical-align: middle;
              margin-right: pxToRem(2);
            }

            .pt__account {
              font-size: pxToRem(11);
            }
          }

          .text-item__number {
            color: #E1E1E2;
            position: relative;
            font-weight: 400;

            span {
              font-size: pxToRem(12);
            }

            &::after {
              content: '';
              position: absolute;
              left: 0px;
              bottom: pxToRem(5);
              top: pxToRem(5);
              width: pxToRem(1);
              background: #2C2C2C;
            }

            &::before {
              content: '';
              position: absolute;
              right: pxToRem(0);
              bottom: pxToRem(5);
              top: pxToRem(5);
              width: pxToRem(1);
              background: #2C2C2C;
            }
          }
        }
      }
    }

    &__list__point_bottom {
      margin-top: pxToRem(16);

      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          font-size: pxToRem(12);
          border-radius: pxToRem(1);
          width: 50%;
          align-items: center;
          margin-bottom: pxToRem(8);
          margin-bottom: pxToRem(8);
          -webkit-box-flex: 0;
          flex: 0 0 50%;
          -webkit-box-flex: 0;

          &:nth-child(2n + 1) {
            padding-right: pxToRem(4);
          }

          &:nth-child(2n) {
            padding-left: pxToRem(4);
          }

          div {
            background: #0d0d0d;
            display: flex;
            justify-content: space-between;
            padding: 0 pxToRem(10);

            span {
              line-height: pxToRem(36);

              &:first-child {
                color: #A4A4A5;
                font-size: pxToRem(12);
              }

              &.num__point {
                font-size: pxToRem(14);
              }
            }
          }
        }
      }
    }
  }
}

.player-page {
  &__search__team {
    font-family: 'Hiragino Kaku Gothic Pro';

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        font-size: pxToRem(12);
        border-radius: pxToRem(1);
        width: 50%;
        align-items: center;
        margin-bottom: pxToRem(8);
        margin-bottom: pxToRem(8);
        flex: 0 0 50%;
        display: flex;
        justify-content: space-between;
        -webkit-box-flex: 0;
        margin-bottom: pxToRem(10);
        position: relative;

        &:nth-child(2n + 1) {
          padding-right: pxToRem(12);
          span {
            padding-left: pxToRem(10);
          }
        }

        &:nth-child(2n) {
          span {
            padding-right: pxToRem(10);
          }
          &::after {
              content: 'VS';
              position: absolute;
              left: pxToRem(-9);
              top: pxToRem(17);
              font-size: pxToRem(9);
              font-weight: 600;
              color: rgba(255, 255, 255, 0.6);;
          }
          padding-left: pxToRem(8);
      }

      span {
        color: #fff;
        display: inline-block;
        font-size: pxToRem(14);
      }

        .game__item {
          display: flex;
          width: 100%;
          background: #181818;
          border: pxToRem(1) solid #383839;
          box-sizing: border-box;
          height: pxToRem(42);
          border-radius: pxToRem(2);
          overflow: hidden;
          padding-right: pxToRem(10);
          align-items: center;
          
          &__team__image {
            img {
              width: pxToRem(40);
              margin-left: pxToRem(-12);
              margin-top: pxToRem(10);
              margin-right: pxToRem(5);
            }
          }

          &__team__name {
            text-align: left;

            p {
              font-size: pxToRem(11);
              margin-bottom: pxToRem(5);
              color: #E1E1E2;
              font-weight: 600;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            span {
              font-size: pxToRem(10);
              color: #A4A4A5;
            }
          }
        }

        &.choose__item {
          .game__item {
            border: 1px solid #D6B60A;
            background-color: #0D0D0D;
          }
        }
      }

      &:first-child {
        li {

          &:nth-child(1),
          &:nth-child(2) {
            justify-content: center;
            margin-bottom: 14px;

            div {
              background: #0D0D0D;
              height: pxToRem(24);
              font-size: pxToRem(10);
              display: flex;
              // width: 100%;
              align-items: center;
              justify-content: center;
              font-weight: 600;
            }
          }

          &:nth-child(2) {
            &::after {
              display: none;
            }
          }
        }
      }
    }
  }

  &__search__name {
    font-family: "Hiragino Kaku Gothic Pro";

    &__group__input {
      position: relative;
      overflow: hidden;
      margin-bottom: pxToRem(20);

      input {
        width: 100%;
        height: pxToRem(40);
        background: #181818;
        box-shadow: inset 1px 1px 3px rgba(255, 255, 255, 0.02) !important;
        border-radius: 2px;
        display: flex;
        align-items: center;
        outline: none !important;
        border: none !important;
        padding-left: pxToRem(40);
        font-size: pxToRem(14);
        font-weight: normal;
        color: #E1E1E2;
      }

      .search__inut {
        position: absolute;
        left: pxToRem(12);
        top: pxToRem(12);
      }

      .clear__input {
        position: absolute;
        width: pxToRem(16);
        height: pxToRem(16);
        display: flex;
        right: pxToRem(12);
        top: pxToRem(12);
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
        background: #383839;
        align-items: center;
      }
    }

    .list__search {
      text-align: left;

      &__title {
        font-size: pxToRem(14);
        color: #E1E1E2;
        margin-bottom: pxToRem(15);
      }

      ul {
        li {
          padding-left: pxToRem(30);
          padding-bottom: pxToRem(15);
          position: relative;
          font-size: pxToRem(14);
          font-weight: 100;
          line-height: pxToRem(21);
          color: #A4A4A5;

          img {
            position: absolute;
            left: pxToRem(5);
            top: pxToRem(3);
          }

          span {
            color: #E1E1E2;
            font-weight: 600;
          }
        }

        overflow-y: auto;
        height: calc(100vh - 175px);
      }
    }
  }
}

@media only screen and (max-width: 410px) {
  .detail-player__topic__left__uniform {
    margin-left: pxToRem(-41);
  }
}

.modal-filter__team {
  justify-content: space-between;
  li {
    width: 100%;
    height: pxToRem(40);
    border-radius: 0;
    background: #181818;

    &:first-child {
      margin-right: pxToRem(0.5);
      border-top-left-radius: pxToRem(3);
      border-bottom-left-radius: pxToRem(3);
    }

    &:last-child {
      margin-left: pxToRem(0.5);
      border-top-right-radius: pxToRem(3);
      border-bottom-right-radius: pxToRem(3);
    }

    &.tab__header-item--active {
      background: #383839;
    }
  }
}

.list-group__team {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  
  ul {
    flex: 1 1 50%;
    box-shadow: 0 0 0 1px black;
    margin-bottom: 10px;

    &:first-child {
      li:nth-child(1) {
        margin-bottom: 0.625rem;
      }

      li:nth-child(2) {
        margin-bottom: 0.625rem;

        div {
          background: #181818;
          height: 2.625rem;
          justify-content: start;
        }
      }
    }

    li {
      width: 100%;
      flex: auto;

      &:nth-child(2n) {
        padding: 0;
      }

      &:nth-child(2n + 1) {
        padding-right: 0;
      }

      &:first-child {
        margin-bottom: pxToRem(8);
        background: #0D0D0D;
        height: pxToRem(30);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &:nth-child(2n) {
      padding-left: pxToRem(8);
    }

    &:nth-child(2n + 1) {
      padding-right: pxToRem(8);
    }
  }
}

.detail-player__card {
  text-align: left;
  padding: pxToRem(5) 0 pxToRem(17) 0;
  font-size: pxToRem(14);

  span {
    display: inline-block;
    margin-right: pxToRem(16);
    
    &.po_card {
      position: relative;
      padding-left: pxToRem(21);
      font-size: pxToRem(15);

      &:after {
        content: '';
        border-radius: pxToRem(1);
        position: absolute;
        left: 0;
        width: pxToRem(14);
        height: pxToRem(18);
        background-color: #FFE600;
      }

      &.red {
        &:after {
          background: #FF3131;
        }
      }
    }
  }
} 
