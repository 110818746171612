.mypage-edit {
  // max-width: pxToRem(700);
  margin: 0 auto;

  &__avatar {
    display: flex;
    justify-content: center;
    margin-top: pxToRem(44);
  }
  &__wrapper {
    padding: 0 pxToRem(16);

    &__profile-empty {
      cursor: pointer;
      width: pxToRem(64);
      height: pxToRem(64);
      border-radius: 50%;
      display: flex;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      justify-content: center;
      align-items: center;
      img {
        width: pxToRem(24);
        height: pxToRem(24);
      }
    }
  }

  &__form {
    .custom-input-class {
      color: #e1e1e2;
      font-family: $primary-font;
      font-size: pxToRem(16);
      line-height: pxToRem(24);
    }

    &__password {
      margin-top: pxToRem(40);
    }

    .custom-wrapper {
      .input__box {
        padding: 0 !important;
      }
    }
    .password-confirm {
      margin-top: pxToRem(8);
    }
  }

  &__btn-submit {
    width: 100%;
    background-color: #c69e00;
    border-radius: pxToRem(42);
    height: pxToRem(42);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: pxToRem(50);
    cursor: pointer;
    button {
      font-family: $primary-font;
      color: #fff;
      background-color: transparent;
      border: none;
      outline: none;
    }
  }
}

.crop-container {
  height: 65vh;
}
