.sign-up {
  width: 100%;
  &__form {
    width: 100%;
    margin-top: pxToRem(30);

    &__password,
    &__email,
    &__password,
    &__promotion-code {
      margin-top: pxToRem(10);
    }

    &__button-wrapper {
      width: 100%;
      margin-top: pxToRem(40);

      button {
        width: 100%;
        border: none;
        outline: none;
        height: pxToRem(50);
        background-color: #c69e00;
        color: #fff;
        font-family: $primary-font;
        font-weight: bold;
        border-radius: pxToRem(32);
      }
    }

    &__checkbox {
      margin-top: pxToRem(30);
    }
  }

  &__link {
    margin-top: pxToRem(38);
    cursor: pointer;
    text-align: center;

    &__text {
      color: #e1e1e2;
      font-size: pxToRem(14);
      padding: 0;
      border: none;
      border-bottom: 1px solid #e1e1e2;
      font-family: $primary-font;
      font-weight: bold;
      padding-bottom: 3px;
      text-align: center;
      background: none;
    }

    .register-link {
      margin-top: pxToRem(15);
    }
  }
}

.error-verify-code {
  text-align: left;
  font-size: pxToRem(13);
  font-weight: 300;
  line-height: 1.5;
  color: #000000b3;
}
