@import 'react-responsive-select/dist/react-responsive-select.css';
@import 'rodal/lib/rodal.css';

.main-layout__content {
  margin: auto;
  max-width: pxToRem(700);
}

.payment-top__page {
  font-family: "Hiragino Kaku Gothic Pro";

  .main-layout {
    padding: pxToRem(26) 0px 0px;

    &__content {
      padding-right: 16px;
      padding-left: 16px;
    }
  }

  .box-info {
    height: pxToRem(57);
    border: 1px solid #D6B60A;
    font-size: pxToRem(11);
    font-weight: 600;
    line-height: pxToRem(18);
    text-align: center;
    padding-top: pxToRem(10);
    margin-bottom: pxToRem(25);
    color: #fff;

    span {
      color: #D6B60A
    }
  }

  .tab-info {
    .payment__top {
      justify-content: space-between;

      .tab__header-item--active {
        background: none;
        border-bottom: pxToRem(1) solid #D6B60A;
        border-radius: pxToRem(0);

        div {
          color: #D6B60A;
        }
      }

      .tab__label {
        font-size: pxToRem(10);
        font-weight: normal;
        text-align: center;
      }
    }

    .box-info__card {
      border: 0.0625rem solid #505050;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 8.4375rem;
      flex-flow: column;
      width: 100%;
      text-align: center;

      &.card-bangkok__bank {
        flex-flow: inherit;

        .img-bangkok_bank {
          margin-right: pxToRem(10);

          img {
            width: pxToRem(80);
            height: auto;
            margin-bottom: 0;
          }
        }
      }

      span {
        color: #A4A4A5;
        font-size: pxToRem(12);
        line-height: pxToRem(18);
      }

      h2 {
        font-family: "Hiragino Kaku Gothic Pro";
        font-style: normal;
        font-weight: 600;
        font-size: pxToRem(20);
        line-height: pxToRe(24);
        font-weight: 600;
        color: #E1E1E2;
        margin-top: pxToRem(5);
      }

      img {
        margin-bottom: pxToRem(8);
        height: 90px;
      }
    }
  }

  .content-info {
    .head--info {
      display: flex;
      justify-content: center;
      font-style: normal;
      font-weight: 600;
      font-size: pxToRem(15);
      line-height: pxToRem(22);
      color: #E1E1E2;

      span {
        width: pxToRem(18);
        height: pxToRem(18);
        border: pxToRem(1) solid #d6b60a;
        border-radius: 50%;
        display: flex;
        margin-top: pxToRem(4);
        align-items: center;
        justify-content: center;
        margin-right: pxToRem(5);
      }
    }
  }

  .list-content {
    margin-top: pxToRem(22);

    ul {
      margin: 0;
      padding: 0;

      li {
        display: flex;
        font-style: normal;
        font-weight: normal;
        font-size: pxToRem(12);
        line-height: pxToRem(17);
        margin-bottom: pxToRem(37);
        color: #E1E1E2;
        position: relative;

        &.item {
          margin-bottom: pxToRem(13);
        }

        div {
          width: calc(100% - 2.5rem);
        }

        div.left {
          width: pxToRem(40);
          padding-top: pxToRem(5);

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: pxToRem(23.14);
            height: pxToRem(23.14);
            background: #1AB892;
            border-radius: 50%;
          }
        }

        .note {
          display: inline-block;
          position: absolute;
          bottom: pxToRem(-30);
          left: 0;
          right: 0;
          text-align: center;

        }
      }
    }
  }

  .payment-page__group__button {
    display: flex;
    justify-content: space-between;
    flex-flow: inherit;

    button {
      font-size: pxToRem(12);

      &:first-child {
        margin-right: pxToRem(9);
      }

      &:last-child {
        margin-left: pxToRem(9);
      }
    }
  }
}

.payment-page {
  .padding__page {
    min-height: pxToRem(600);
    padding: 0px pxToRem(16);
    font-family: 'Hiragino Kaku Gothic Pro';
    max-width: pxToRem(650);
    margin: 0 auto;
    height: 100vh;
    background-color: #000;
    position: relative;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
      font-style: normal;
      font-weight: 600;
      font-size: pxToRem(14);
      line-height: pxToRem(21);
      color: #E1E1E2;
      padding: pxToRem(43) 0;
    }
  }

  &__group__button {
    display: flex;
    flex-flow: column;

    button {
      background: #C69E00;
      border-radius: pxToRem(42);
      color: #FFFFFF !important;
      height: 50px;
      display: flex;
      width: 100%;
      font-size: pxToRem(16);
      align-items: center;
      justify-content: center;
      margin-bottom: pxToRem(16);

      &.payment-free {
        background-color: transparent;
        border-color: #C69E00;
        color: #C69E00 !important;
      }
    }
  }

  &__content__item {
    margin-bottom: pxToRem(28);

    .page__title {
      margin-bottom: pxToRem(8);
      justify-content: space-between;
      display: flex;
      align-items: center;

      .image__visa {
        padding-right: pxToRem(42);
        display: block;
        position: relative;

        img {
          width: pxToRem(34.62);
        }

        &::after {
          content: '';
          height: pxToRem(19.64);
          width: pxToRem(19.64);
          background: #EB001B;
          border-radius: 50%;
          position: absolute;
          right: pxToRem(13);
          top: pxToRem(-4);
          z-index: 2;
        }

        &::before {
          content: '';
          height: pxToRem(19.64);
          width: pxToRem(19.64);
          background: #F79E1B;
          border-radius: 50%;
          position: absolute;
          right: pxToRem(0);
          top: pxToRem(-4);
          z-index: 1;
        }
      }

      label {
        color: #E1E1E2;
        font-size: pxToRem(12);
        display: flex;

        span {
          &.validate {
            font-size: pxToRem(8);
            line-height: pxToRem(12);
            height: pxToRem(14);
            width: pxToRem(24);
            background: #C01E45;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: pxToRem(2);
            margin-left: pxToRem(6);
          }

          &.help {
            margin-left: pxToRem(6);
            border-radius: 50%;
            height: pxToRem(16);
            width: pxToRem(16);
            background: #383839;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: pxToRem(-1);
          }
        }
      }
    }
  }

  &__content__input {
    .rrs__button {
      line-height: pxToRem(38);
      width: pxToRem(83);
      margin-right: pxToRem(8);
      outline: none !important;
      color: #e1e1e2;
    }

    .rrs__label {
      background: #181818;
      border-radius: pxToRem(2);
      border-color: #181818 !important;
    }

    &.code__visa {
      .input__box {
        width: pxToRem(83);
      }
    }

    &.content__select {
      display: flex;

      .caret-icon {
        top: pxToRem(16);
        fill: #A4A4A5;
      }

      .rrs--has-changed {
        .rrs__label {
          color: inherit;
        }
      }
    }

    .input__box {
      padding-left: 0;

      input {
        padding-left: 0.5rem;
      }
    }
  }

  &__bottom__action {
    position: absolute;
    bottom: 0;
    left: pxToRem(16);
    right: pxToRem(16);
    padding-bottom: pxToRem(55);

    .button__payment {
      button {
        margin-top: pxToRem(24);
        background: #C69E00;
        border-radius: pxToRem(42);
        height: pxToRem(50);
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
      }
    }

    &__content {
      height: pxToRem(68);
      background: #181818;
      border-radius: pxToRem(2);
      display: flex;
      justify-content: space-between;
      padding: 0 pxToRem(16);
      align-items: center;
      position: relative;
      color: #E1E1E2;
      padding-bottom: pxToRem(10);

      :after {
        content: '';
        height: pxToRem(1);
        left: pxToRem(10);
        right: pxToRem(10);
        bottom: pxToRem(8);
        background: #D6B60A;
        position: absolute;
      }

      .title {
        font-size: pxToRem(14);
        font-weight: 600;
      }

      .point {
        span {
          &:first-child {
            font-size: pxToRem(14);
            color: #E1E1E2;
            padding-right: pxToRem(11);
            font-weight: 100;
            display: inline-block;
          }

          &:last-child {
            font-size: pxToRem(18);
            font-weight: 600;
          }
        }
      }
    }
  }
}

.rrs__option.rrs__option--selected {
  color: #212529;
  background: #e9e9e9;
}

.rrs__option {
  font-size: pxToRem(15);

  &:focus {
    outline: none !important;
  }
}

@media screen and (max-width: 768px) {
  .rrs--options-visible .rrs__options {
    top: pxToRem(57);
  }
}

.rodal-mask {
  background: #000000;
}

.rodal-dialog {
  height: pxToRem(336) !important;
  background-color: transparent;
  font-family: 'Hiragino Kaku Gothic Pro';

  .content__popup {
    background: #181818;
    border: 1px solid #383839;
    box-sizing: border-box;
    border-radius: pxToRem(10);
    color: #E1E1E2;
    min-height: pxToRem(336);
    display: flex;
    width: 100%;
    flex-flow: column;
    align-items: center;

    p {
      text-align: center;
      line-height: pxToRem(21);
    }

    &__image {
      margin-top: pxToRem(82);

      span {
        display: inline-block;
        position: relative;
        overflow: hidden;

        .notice {
          position: absolute;
          z-index: 1;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          height: pxToRem(56);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        margin-bottom: pxToRem(28);
      }
    }

    &__button {
      margin-top: pxToRem(82);
      padding: 0 pxToRem(27);
      display: flex;
      width: 100%;

      button {
        height: pxToRem(50);
        display: flex;
        background: #C69E00;
        border-radius: pxToRem(42);
        width: 100%;
        justify-content: center;
        color: #FFFFFF;
        align-items: center;
      }

      &.faild {
        margin-top: pxToRem(61);
      }
    }
  }

  .rodal-close {
    display: none;
  }
}

.payment__navigate-group {
  max-width: pxToRem(700);
  margin: 0 auto;
  padding-top: pxToRem(31);

  button {
    height: pxToRem(50);
    border-radius: pxToRem(42);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: pxToRem(16);
    border-width: pxToRem(1);
  }

  &__payment button {
    margin-bottom: pxToRem(16);
    color: #FFFFFF;
    border: 0;
    background: #C69E00;
  }

  &__gohome button {
    margin-bottom: pxToRem(16);
    color: #D6B60A;
    border-color: #C69E00;
    background: #000000;
  }
}

#card_number,
#cvc,
#exp {
  height: pxToRem(40);
  background-color: #181818;
  border-radius: pxToRem(2);
  color: #fff;
  padding-left: pxToRem(16);
  padding-top: pxToRem(10);
}

#cvc,
#exp {
  width: pxToRem(90);
}

.show__error__payment {
  .StripeElement {
    border: 1px solid #FF8E8E;
    background-color: rgba(255, 142, 142, .21) !important;
  }

  .error-msg {
    color: #FF8E8E;
  }
}

.img-qr__code {
  button {
    &:first-child {
      background-color: #89740f;
      color: #fff !important;
    }

    &:last-child {
      background: #fff;
    }
  }
}

.payment_process {
  .tab-info {
    .box-info__card {
      height: auto;
      padding: pxToRem(18);
    }
  }

  .img-qr__code {
    img {
      width: 100%;
    }
  }

  .payment-page__group__button {
    margin-top: pxToRem(20);

    button {
      font-size: pxToRem(14);
    }
  }
}

.qr-code__container {
  background: #fff;
  padding: pxToRem(16);
  margin-bottom: 10px;
}

.helper__box {
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  height: 0px;
  overflow: hidden;

  .img {
    margin-top: pxToRem(20);
  }

  &.active__box {
    height: auto;
  }

  strong {
    font-weight: bolder !important;
  }

  &>ul {
    text-align: left;
    margin-left: 10px;
    line-height: pxToRem(18);
    font-size: pxToRem(15);
    color: #fff;
    font-family: sans-serif;
    font-weight: 100;

    li {
      list-style: disc;

      &:first-child {
        margin-bottom: pxToRem(15);
      }
    }

    ul {

      // margin-left: pxToRem(10);
      ol {
        &:first-child {
          margin-top: pxToRem(10);
        }

        margin-bottom: pxToRem(10);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.position-relative {
  position: relative;
}

.over-li {
  position: absolute;
  z-index: 40;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
}

.img-qr__code {
  .qr-code__container {
    width: 100%;

    img {
      height: auto !important;
    }
  }
}

.btn-payment_getway {
  background: #C69E00;
  border-radius: 2.625rem;
  color: #FFFFFF !important;
  height: 50px;
  display: flex;
  width: 100%;
  font-size: 0.75rem;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.payment-page__banking__title {
  border: 2px solid #1C2027;
  height: pxToRem(46);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: pxToRem(44) 0;
  color: #FFFFFF;
  font-size: pxToRem(14);
  background: #1C2027;
}

.banking-top {
  margin-bottom: pxToRem(24);

  h3 {
    font-size: pxToRem(14);
    color: #fff;
    margin-bottom: pxToRem(5);
  }

  ul {
    background: rgba(255, 255, 255, 0.05);

    li {
      padding-left: 24px;
      flex-wrap: wrap;
      height: pxToRem(68);
      padding-top: pxToRem(16);
      border-bottom: 1px solid rgba(255, 255, 255, 0.04);

      span {
        font-size: pxToRem(12);
        color: rgba(255, 255, 255, 0.6);
      }

      p {
        margin-top: pxToRem(2);
        color: rgba(255, 255, 255, 0.87);
        line-height: pxToRem(24);
        font-size: pxToRem(16);
        width: 100%;
      }

      &:last-child {
        border: 0px;
      }
    }
  }
}

.banking-bottom {
  color: #fff;
  margin-bottom: pxToRem(10);

  h3 {
    font-size: pxToRem(14);
    margin-bottom: pxToRem(5);
  }

  .info-banking {
    background-color: rgba(255, 255, 255, 0.05);
    padding-left: pxToRem(24);
    font-size: pxToRem(16);
    height: pxToRem(56);
    display: flex;
    align-items: center;
  }
}

.banking-comment {
  color: rgba(255, 255, 255, 0.87);
  font-size: pxToRem(12);
  font-weight: 400;

  p {
    padding-bottom: pxToRem(16);
    line-height: pxToRem(20);
  }

  .box-image {
    margin: pxToRem(40) 0;
  }
}

.banking-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 pxToRem(24);
  color: #fff;
  background: #206116;
  border: 2px solid #15590A;
  height: pxToRem(46);
  margin-top: pxToRem(24);
  margin-bottom: pxToRem(16);

  span {
    font-size: pxToRem(14);
  }
}

.banking-button {
  padding: 0 pxToRem(85);
  margin-bottom: pxToRem(24);

  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    background: #D20665;
    height: pxToRem(48);
    border-radius: 0;
  }
}

.titleBankingSuccess {
  font-size: pxToRem(18);
}

.banking-center * {
  text-align: center;
}

.pd-0 {
  padding: 0 !important;
}

.banking-callback {
  .box-image {
    &>span {
      display: inline-block;
      position: relative;

      .notice {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .btn--button {
    background: #c69e00;
    border-radius: pxToRem(30);
  }
}