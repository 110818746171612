.team-3 {
  .player {
    margin-top: pxToRem(30);
  }
}

.my-team-3 {
  .my-team__label {
    margin-top: pxToRem(20);
  }
}
