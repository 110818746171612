.login {
  width: 100%;

  &__form {
    width: 100%;
    margin-top: pxToRem(30);

    &__password {
      margin-top: pxToRem(10);
    }

    &__button-wrapper {
      width: 100%;
      margin-top: pxToRem(32);

      button {
        width: 100%;
        border: none;
        outline: none;
        height: pxToRem(50);
        background-color: #c69e00;
        color: #fff;
        font-family: $primary-font;
        font-weight: bold;
        border-radius: pxToRem(42);
      }
    }
  }
  &__link {
    margin-top: pxToRem(32);
    cursor: pointer;
    text-align: center;

    &__text {
      color: #a4a4a5;
      font-size: pxToRem(13);
      padding: 0;
      border: none;
      border-bottom: 1px solid #a4a4a5;
      font-family: $primary-font;
      font-weight: bold;
      padding-bottom: 3px;
      text-align: center;
      background: none;
    }

    .register-link {
      margin-top: pxToRem(93);
      font-family: $primary-font;
      font-weight: 600;
      font-size: pxToRem(14);
      color: #d6b60a;
      border: 1px solid #c69e00;
      padding: pxToRem(10) pxToRem(35);
    }
  }
}

.error-request {
  margin: pxToRem(16) auto 0px;
  color: #c69e00;
  font-size: pxToRem(14);
}

.facebook-login {
  margin-top: pxToRem(35);
  width: 100%;
  height: pxToRem(50);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px;
  background: #4167B2;
  color: #fff;
  font-weight: 500;
  border-radius: pxToRem(32);
  font-family: $second-font;
  position: relative;
  span {
    position: absolute;
    width: pxToRem(30);
    height: pxToRem(30);
    left: pxToRem(15);
    top: pxToRem(10);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: pxToRem(5);
    background: #fff;
    img {
      width: pxToRem(20)
    }
  }
}

.line-login__form {
  font-family: $second-font;
  margin-top: 2rem;
  font-size: pxToRem(13);
  font-weight:600;
  height: 1px;
  width: 100%;
  background: #686868;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    color: #686868;
    display: flex;
    width: pxToRem(30);
    height: pxToRem(30);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    padding: 0 pxToRem(9);
    border: 1px solid #0d0d0d;
    background: #0d0d0d;
  }
}
.error-not__exist__facebook {
  font-size: 0.875rem;
  color: #c69e00;
  display: inline-block;
  margin-top: 1rem;
  line-height: 20px;
}