.account-page {
  background-color: #0d0d0d;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: pxToRem(40) pxToRem(15) pxToRem(100) pxToRem(15);
  width: 100%;

  &__wrapper {
    width: 100%;
    max-width: pxToRem(550);
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__text {
      font-size: pxToRem(14);
      font-family: $primary-font;
      font-weight: bold;
      color: #fff;
      margin-top: pxToRem(10);
      text-align: center;
      p:nth-child(2) {
        margin-top: pxToRem(5);
      }
    }
  }

  &__login-line {
    background: #00b900;
    border-radius: 42px;
    position: relative;
    height: pxToRem(50);
    margin-top: pxToRem(27);
    cursor: pointer;
    width: 100%;
    ._1RLww {
      height: pxToRem(50);
    }
    ._RU-K2 {
      background: none;
      height: 18px;
      width: 93%;
    }

    &__content {
      position: absolute;
      top: 15px;
      left: 50%;
      transform: translate(-50%);
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        color: #fff;
        font-family: $primary-font;
        font-size: pxToRem(16);
        font-weight: 600;
        margin-left: pxToRem(10);
        min-width: pxToRem(125);
      }
    }
  }

  .custom-link {
    margin-top: pxToRem(40);
  }
}
