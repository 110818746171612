@include breakpoint(xm480) {
  .input {
    font-size: pxToRem(12) !important;
  }
}
.input {
  width: 100%;
  color: #505050;
  background-color: transparent;
  padding: pxToRem(10);
  padding-left: pxToRem(5);
  outline: none;
  border: none;
  letter-spacing: 1.23px;
  font-size: pxToRem(14);
  font-weight: 600;
  font-family: $primary-font;

  @include placeholder {
    color: #a4a4a5;
  }

  &__label {
    font-family: $primary-font;
    font-size: pxToRem(12);
    font-weight: bold;
    line-height: pxToRem(18);
    color: #e1e1e2;
    margin-bottom: pxToRem(16);
  }

  &__box {
    display: flex;
    justify-content: center;
    background: #181818;
    box-shadow: inset 1px 1px 3px rgba(255, 255, 255, 0.02);
    border-radius: 2px;
    width: 100%;
    padding: 0 0 0 pxToRem(8);
    height: pxToRem(40);

    &__icon {
      display: flex;
      justify-content: center;
      margin-right: pxToRem(8);
      opacity: 0.5;
      align-items: center;

      // img {
      //   width: pxToRem(20);
      //   height: pxToRem(20);
      // }
    }
  }
}

.error-msg {
  color: #a90000;
  font-size: pxToRem(14);
  font-family: $primary-font;
  margin-top: pxToRem(10);
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #062332 inset;
  -webkit-text-fill-color: $color-input !important;
}
